import React, { useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import ReactECharts from "echarts-for-react";

// import echarts
import * as echarts from 'echarts';
import BannerText from '../BannerText';

const MultiYAxisLineChart = observer(({ series, tooltipFormatter, titles, height = "600px", loading = false, animation = true, yAxisTitles }:
  { series: any, tooltipFormatter?: (value: any) => string, titles: string[] | number[], height?: string, loading?: boolean, yAxisTitles?: string[], animation?: boolean }) => {
  // const theme = useTheme();

  // console.log(data)

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: tooltipFormatter
    },
    animation: animation,
    grid: {
      top: 50,
      left: 80,
      right: 90,
      bottom: 120,
    },
    toolbox: {
      show: true,
      feature: {
        // dataZoom: {
        //   yAxisIndex: 'none'
        // },
        dataView: { readOnly: true },
        // magicType: { type: ['line', 'bar'] },
        // restore: {},
        saveAsImage: {}
      }
    },
    legend: {
      show: true,
      orient: 'horizontal',
      bottom: 60
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: titles
    },
    yAxis: yAxisTitles?.map((yAxisTitle, index) => ({
      type: 'value',
      name: yAxisTitle,
      axisLabel: {
        // formatter: '{value} °C'
      },
      // axisLine: {
      //   show: true
      // },
      // min: "min"
    })),
    series: series
  };

  return (
    <div id="chart" style={{ width: "100%", height: `${height}` }}>
      {
        loading
          ? <BannerText text={"Loading..."} />
          : <ReactECharts option={option} style={{ width: "100%", height: "100%" }} />
      }
    </div>
  );
})

export default MultiYAxisLineChart
