import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData, getOrganizationFEData } from '../../../api/explorerService'
import { ClimateScenario, EntityLevel, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import { APIErrorHandler, FormatFYChartTitle, FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions, GetScenarioName, GetMinYAxisValue } from '../../../utils/general'
import CompareFilters from '../../CompareFilters'
import RegionDataTable from '../../charts/region-data-table'
import { FilterContext } from '../../../context/filter-bar-context'
import { Box, IconButton, Typography } from '@mui/material'
import VmModal from '../../VmModal'
import SimpleBarChart from '../../charts/simple-bar-chart'
import { Close } from '@mui/icons-material'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import { useSnackbar } from 'notistack'
import { FilterStoreContext } from '../../../context/filter-store-context'
import SimpleLineChart from '../../charts/simple-line-chart'
import { defaultDataKeyColours, RandomRGB } from '../../../utils/colour'


const SummaryFERegionSection = observer(({ }: {}) => {
  const { reportPeriod, compareToYears, } = useContext(FilterContext)
  const { enqueueSnackbar } = useSnackbar();
  const filterStore = useContext(FilterStoreContext);

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')

  const [chartData, setChartData] = useState<any>([])
  const [dataKeys, setDataKeys] = useState<any[]>([])

  // const [climateChangeModalDataKeys, setClimateChangeModalDataKeys] = useState<any[]>([])
  // const [climateChangeModalChartData, setClimateChangeModalChartData] = useState<any[]>([])

  // climate change modal
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [selectedClimateScenario, setSelectedClimateScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)
  const [climateChangeModalFyOptions, setClimateChangeModalFyOptions] = useState<number[]>([])
  const [climateChangeModalLineSeriesDataWithFy, setClimateChangeModalLineSeriesDataWithFy] = useState<any[]>([])
  const [climateChangeOnlyShowTotal, setClimateChangeOnlyShowTotal] = useState<boolean>(false)

  // const [stressTestLineChartData, setStressTestLineChartData] = useState<any>([])
  // const [stressTestFyOptions, setStressTestFyOptions] = useState<number[]>([])

  const [loading, setLoading] = useState<boolean>(false)
  const [climateChangeModalLoading, setClimateChangeModalLoading] = useState<boolean>(false)

  const dataKeyColours = useRef<{ [key: string]: string }>(defaultDataKeyColours)

  const refreshData = (fy: number = reportPeriod) => {
    setLoading(true)
    getOrganizationFEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "emissions", "region")
      .then((data) => {
        let rawData = data.data;
        if ("data" in rawData) rawData = rawData.data;

        if (!Array.isArray(rawData)) {
          // Handle case where rawData is in wrong format
          setChartData([]);
          return;
        }

        rawData = rawData.sort((a: any, b: any) => a.FY - b.FY);

        const keys = Object.keys(rawData[0])
          .filter((key) => !["FY"].includes(key))
          .sort((a: string, b: string) => a.localeCompare(b))
          .map((key) => ({ key, title: key }));

        // let tempLineChartData: any[] = [];
        // keys.forEach((key: any) => {
        //   tempLineChartData.push({
        //     name: key.title,
        //     type: 'line',
        //     data: rawData.map((data: any) => ({
        //       ...data[key.key],
        //       fy: data.FY
        //     })),
        //     symbol: "roundRect",
        //     symbolSize: 16,
        //   })
        // })

        setDataKeys(keys)
        setChartData(rawData.map((a: any) => ({ ...a, FY: +a.FY })))
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setLoading(false))
  }

  const handleClickClimateChange = () => {
    setShowClimateChangeModal(true)
    setClimateChangeModalLoading(true);

    let climateChangeChartData: any[] = [];
    let climateChangeFYOptions: any[] = [];

    Promise.all([ClimateScenario.CURRENT, ClimateScenario.DISORDERLY].map((climateScenario: ClimateScenario) => {
      return getOrganizationFEData({
        force_refresh: false,
        include_trajectory: true,
        climateScenario: climateScenario,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, filterStore.entityLevel, "emissions", "region")
        .then((data: any) => {
          let rawData = data.data;
          if ("data" in rawData) rawData = rawData.data
          rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

          if (climateChangeFYOptions.length === 0) {
            climateChangeFYOptions = rawData.map((data: any) => data.FY)
          }

          if (rawData.length === 0) return;

          const dataKeys = Object.keys(rawData[0])
            .filter((key: string) => !["FY"].includes(key));

          for (const key of dataKeys) {
            if (!dataKeyColours.current[key]) {
              dataKeyColours.current[key] = RandomRGB();
            }
          }

          dataKeys.forEach((key: any) => {
            climateChangeChartData.push({
              name: `${FormatKeyToLabel(key)} - ${GetScenarioName(climateScenario)}`,
              type: 'line',
              data: rawData.map((data: any) => ({
                ...data[key],
                fy: data.FY,
                climateScenario: climateScenario,
              })),
              climateScenario: climateScenario,
              itemStyle: {
                color: dataKeyColours.current[key] || RandomRGB()
              },
              isTotal: key.toLowerCase() === "total",
            })
          })
        })
        .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
    })
    ).then(() => {
      climateChangeChartData.sort((a: any, b: any) => a.name.localeCompare(b.name))
      
      setClimateChangeModalLineSeriesDataWithFy(climateChangeChartData)
      setClimateChangeModalFyOptions(climateChangeFYOptions)
    })
      .finally(() => setClimateChangeModalLoading(false))

  }

  const handleExportData = () => {
    exportFile(
      GenerateExportUrl("fe", "emissions", "region"),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Financed Emission - Region.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
  }

  useEffect(() => {
    refreshData()
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={handleClickClimateChange}
        changeReportPeriodCallBack={refreshData}
        onClickExport={handleExportData}
        showExport={filterStore.entityLevel === EntityLevel.ASSET}
      />
      <RegionDataTable
        loading={loading}
        tableData={chartData.filter((data: any) => +data.FY >= reportPeriod - compareToYears)}
        dataKeys={dataKeys}
      />

      <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
        <Box className="flex items-center justify-between">
          <Typography variant="h6">Stress Test</Typography>
          <IconButton onClick={() => setShowClimateChangeModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <ClimateChangeToggleBar selectedClimateScenario={selectedClimateScenario} setSelectedClimateScenario={setSelectedClimateScenario} />

        <SimpleLineChart
          key={`climate-change-modal-line-chart-${selectedClimateScenario}-${climateChangeOnlyShowTotal}`}
          yAxisTitle={"tCO2e"}
          animation={false}
          loading={climateChangeModalLoading}
          titles={climateChangeModalFyOptions.filter((fy: number) => fy >= reportPeriod).map(FormatFYChartTitle)}
          // minYAxisValue={GetMinYAxisValue(climateChangeModalLineSeriesDataWithFy)}
          series={climateChangeModalLineSeriesDataWithFy
            .map((data: any) => ({
              ...data,
              type: "line",
              symbol: selectedClimateScenario === data.climateScenario ? 'roundRect' : 'triangle',
              symbolSize: 20,
              lineStyle: {
                width: 2,
                type: selectedClimateScenario === data.climateScenario ? "solid" : "dashed",
                color: data.color
              },
              data: data.data
                .filter((value: any) => +value.fy >= reportPeriod)
                .map((value: any) => {
                  return {
                    ...value,
                    itemStyle: {
                      color: value.color,
                      opacity: selectedClimateScenario === value.climateScenario ? 1 : 0.4,
                    },
                    value: value.value,
                  }
                })
            }))}
        />

      </VmModal>
    </>
  )
})

export default SummaryFERegionSection