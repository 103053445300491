// ErrorBoundary.tsx
import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  fallback?: ReactNode; // Optional custom fallback UI
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
    error: undefined,
  };

  // Called when an error occurs during rendering
  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error,
    };
  }

  // Called after an error is caught
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can log the error to an error reporting service here
    console.error('Error caught by boundary:', error);
    console.error('Error info:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback || (
        <div className="error-boundary flex items-center justify-center h-[350px]">
          <h2>Ooops, something went wrong while rendering this data.</h2>
          {/* <details>
            <summary>Error Details</summary>
            <pre>{"" + this.state.error}</pre>
          </details> */}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;