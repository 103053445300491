import React, { useContext, useEffect, useRef, useState } from 'react'
import RegionDataTable, { TableDataKeyType } from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData, getOrganizationFEData } from '../../../api/explorerService'
import { ClimateScenario, EntityLevel, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import moment from 'moment'
import { APIErrorHandler, FormatFYChartTitle, FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions, GetMinYAxisValue, GetScenarioName } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'
import VmModal from '../../VmModal'
import { Box, Typography, IconButton } from '@mui/material'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import { Close } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { FilterStoreContext } from '../../../context/filter-store-context'
import BannerText from '../../BannerText'
import SimpleLineChart from '../../charts/simple-line-chart'
import { defaultDataKeyColours, lerpColor, RandomRGB } from '../../../utils/colour'
import SimpleBarChart from '../../charts/simple-bar-chart'


const SummeryFEScopeSection = observer(({ }: {}) => {
  const { reportPeriod, compareToYears, setAllowedCompareToYears, } = useContext(FilterContext)
  const { enqueueSnackbar } = useSnackbar();
  const filterStore = useContext(FilterStoreContext);

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])
  const [stressTestModalData, setStressTestModalData] = useState<any>([])

  // climate change modal
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [selectedClimateScenario, setSelectedClimateScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)
  const [climateChangeModalFyOptions, setClimateChangeModalFyOptions] = useState<number[]>([])
  const [climateChangeModalLineSeriesDataWithFy, setClimateChangeModalLineSeriesDataWithFy] = useState<any[]>([])
  const [climateChangeOnlyShowTotal, setClimateChangeOnlyShowTotal] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  const [dataKeys, setDataKeys] = useState<TableDataKeyType[]>([])
  const [stressTestModalDataKeys, setStressTestModalDataKeys] = useState<TableDataKeyType[]>([])
  const [climateChangeModalLoading, setClimateChangeModalLoading] = useState<boolean>(false)

  const dataKeyColours = useRef<{ [key: string]: string }>(defaultDataKeyColours)

  const refreshData = (fy: number = reportPeriod) => {

    setLoading(true)
    getOrganizationFEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "emissions", "scope")
      .then((data) => {
        let rawData = data.data;
        if ("data" in rawData) rawData = rawData.data;

        if (!Array.isArray(rawData)) {
          // Handle case where rawData is in wrong format
          setChartData([]);
          return;
        }
        
        rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

        setChartData(rawData)
        if (rawData.length > 0) {
          const firstFY = rawData[0].FY
          setAllowedCompareToYears(fy - (+firstFY))

          const keys = Object.keys(rawData[0])
            .filter((key) => !["FY"].includes(key))
            .map((key) => ({
              key,
              title: FormatKeyToLabel(key),
              unit: ["NetIntensity", "PhysicalIntensity"].includes(key) ? "tCO2e/ha" : undefined,
              isStatRow: ["GrossEmission", "NetEmissions", "CarbonRemovals"].includes(key)
            }))

          setDataKeys(keys)
        }

        // let tempBarChartData = [];

        // // NON-SUMMARY-DATA, SECTORS
        // Object.keys(rawData[0])
        //   .filter((key: string) => key.startsWith("Scope"))
        //   .forEach((key: string, keyIndex: number) => {
        //     const mainColor = lerpColor("#1d62f4", "#002c8c", keyIndex / (dataKeys.length - 1))

        //     tempBarChartData.push({
        //       name: FormatKeyToLabel(key),
        //       type: 'bar',
        //       stack: "stack1",

        //       barWidth: 80,
        //       symbol: "roundRect",
        //       symbolSize: 16,
        //       data: rawData.map((data: any) => ({
        //         ...data[key],
        //         fy: data.FY
        //       })),
        //       itemStyle: {
        //         color: mainColor
        //       },
        //       // markLine: {
        //       //   data: (keyIndex === 0) ? markLineData : []
        //       // },
        //     })
        //   })

        // tempBarChartData.push({
        //   name: "Net Emission",
        //   type: 'line',
        //   data: rawData.map((data: any) => ({
        //     ...data["NetEmissions"],
        //     value: data["NetEmissions"] ? data["NetEmissions"].value : 0,
        //     fy: data.FY
        //   })),
        //   symbol: "rect",
        //   symbolSize: [80, 5],
        //   lineStyle: {
        //     width: 1,
        //     color: "#0061ff"
        //   },
        //   itemStyle: {
        //     color: "#15F5BA"
        //   }
        // })

        // tempBarChartData.push({
        //   name: "Carbon Removals",
        //   type: 'bar',
        //   stack: "stack1",
        //   barWidth: 80,
        //   data: rawData.map((data: any) => ({
        //     ...data["CarbonRemovals"],
        //     value: (data["CarbonRemovals"] && data["CarbonRemovals"].value) ? -1 * (data["CarbonRemovals"].value) : 0,
        //     fy: data.FY
        //   })),
        //   itemStyle: {
        //     color: "white",
        //     borderColor: "#0061ff",
        //     borderWidth: 1,
        //     borderType: "dashed"
        //   }
        // })

        // setStressTestLineChartData(tempBarChartData)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setLoading(false))
  }

  const handleClimateChangeModal = () => {
    setShowClimateChangeModal(true);
    setClimateChangeModalLoading(true)

    let climateChangeChartData: any[] = [];
    let climateChangeFYOptions: any[] = [];

    Promise.all(
      [ClimateScenario.CURRENT, ClimateScenario.DISORDERLY].map((climateScenario: ClimateScenario) => {
        return getOrganizationFEData({
          force_refresh: false,
          include_trajectory: true,
          climateScenario: climateScenario,
          reportPeriod: "" + reportPeriod,
          entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
        }, filterStore.entityLevel, "emissions", "scope")
          .then((data: any) => {
            let rawData = data.data;
            if ("data" in rawData) rawData = rawData.data
            rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

            if (climateChangeFYOptions.length === 0) {
              climateChangeFYOptions = rawData.map((data: any) => data.FY)
            }

            if (rawData.length === 0) return;

            const dataKeys = Object.keys(rawData[0])
              .filter((key: string) => !["FY"].includes(key));

            // console.log(dataKeys)

            for (const key of dataKeys) {
              if (!dataKeyColours.current[key]) {
                dataKeyColours.current[key] = RandomRGB();
              }
            }

            dataKeys.forEach((key: any) => {
              climateChangeChartData.push({
                name: `${FormatKeyToLabel(key)} - ${GetScenarioName(climateScenario)}`,
                type: 'line',
                data: rawData.map((data: any) => ({
                  ...data[key],
                  fy: data.FY,
                  climateScenario: climateScenario,
                })),
                climateScenario: climateScenario,
                itemStyle: {
                  color: dataKeyColours.current[key] || RandomRGB()
                },
                isTotal: key === "GrossEmission",
              })
            })
          })
          .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      })
    ).then(() => {
      climateChangeChartData.sort((a: any, b: any) => a.name.localeCompare(b.name))
      
      setClimateChangeModalLineSeriesDataWithFy(climateChangeChartData)
      setClimateChangeModalFyOptions(climateChangeFYOptions)
    })
      .finally(() => setClimateChangeModalLoading(false))
  }

  const handleExportData = () => {
    exportFile(
      GenerateExportUrl("fe", "emissions", "scope"),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Financed Emissions - Scope.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
  }

  useEffect(() => {
    if (showClimateChangeModal === false && chartData.length > 0) return;
    refreshData()
  }, [showClimateChangeModal, selectedClimateScenario])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={handleClimateChangeModal}
        changeReportPeriodCallBack={(value: number) => refreshData(value)}
        onClickExport={handleExportData}
        showExport={filterStore.entityLevel === EntityLevel.ASSET}
        defaultBenchmark={false}
        defaultTarget={false}
      />
      {
        loading
          ? <BannerText text={"Loading..."} />
          : <RegionDataTable
            tableData={chartData.filter((data: any) => (+data.FY >= reportPeriod - compareToYears))}
            dataKeys={dataKeys}
            unitCellContent={<p className="opacity-40">tCO2e</p>}
          />
      }

      {
        <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
          <Box className="flex items-center justify-between">
            <Typography variant="h6">Stress Test</Typography>
            <IconButton onClick={() => setShowClimateChangeModal(false)}>
              <Close />
            </IconButton>
          </Box>
          <ClimateChangeToggleBar
            selectedClimateScenario={selectedClimateScenario}
            setSelectedClimateScenario={setSelectedClimateScenario}
            showTotalOnly={climateChangeOnlyShowTotal}
            setShowTotalOnly={setClimateChangeOnlyShowTotal}
          />
          <SimpleLineChart
            key={`climate-change-modal-line-chart-${selectedClimateScenario}-${climateChangeOnlyShowTotal}`}
            yAxisTitle={"tCO2e"}
            animation={false}
            loading={climateChangeModalLoading}
            titles={climateChangeModalFyOptions.filter((fy: number) => fy >= reportPeriod).map(FormatFYChartTitle)}
            // minYAxisValue={GetMinYAxisValue(climateChangeModalLineSeriesDataWithFy.filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true))}
            series={climateChangeModalLineSeriesDataWithFy
              .filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true)
              .map((data: any) => ({
                ...data,
                type: "line",
                symbol: selectedClimateScenario === data.climateScenario ? 'roundRect' : 'triangle',
                symbolSize: 20,
                lineStyle: {
                  width: 2,
                  type: selectedClimateScenario === data.climateScenario ? "solid" : "dashed",
                  color: data.color
                },
                data: data.data
                  .filter((value: any) => +value.fy >= reportPeriod)
                  .map((value: any) => {
                    return {
                      ...value,
                      itemStyle: {
                        color: value.color,
                        opacity: selectedClimateScenario === value.climateScenario ? 1 : 0.4,
                      },
                      value: value.value,
                    }
                  })
              }))}
          />
        </VmModal>
      }
    </>
  )
})

export default SummeryFEScopeSection
