import React, { useContext, useEffect, useState } from 'react';
import { Alert, Box, IconButton, Skeleton, Snackbar, Tooltip, Typography } from '@mui/material';
import Layout from '../layouts/Layout';
import { FilterLevel, FilterLevelOne, FilterLevelThree, FilterLevelTwo } from '../components/summary/filter-section';
import { observer } from 'mobx-react-lite';
import { ArrowDownward, ArrowForwardIos, ArrowUpward, Close, Info } from '@mui/icons-material';
import theme from '../styles/theme';

import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import DashboardPieChart from '../components/charts/dashboard-pie-chart';
import DashbaordBarChart from '../components/charts/dashboard-bar-chart';
import ChangesLabel from '../components/changes-label';
import VmDropdown from '../components/VmDropdown';
import { FilterContext } from '../context/filter-bar-context';
import { getDashboardData, getOrganizationAEData } from '../api/explorerService';
import { ClimateScenario, EntityLevel, OrganizationTypes, PRRiskLevel, SearchType } from '../utils/options';
import SecureStorage from '../services/secureStorage';
import { APIErrorHandler, FormatKeyToLabel, GetViewingEntityLevel, ParseChangeValues } from '../utils/general';
import PRGradientStatsCircle from '../components/PrGradientStatsCircle';
import PrDecileTable from '../components/PRDecileTable';
import VmModal from '../components/VmModal';
import CriteriaHeader from '../layouts/criteria-header';
import { FilterStoreContext } from '../context/filter-store-context';
import { useSnackbar } from 'notistack';
import VmHelp from '../components/VmHelp';

const ChartHeight = "250px";

const SummaryDashboardPage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const filterStore = useContext(FilterStoreContext);

  const [searchParams, setSearchParams] = useSearchParams()
  const secureStorage = SecureStorage.getInstance();
  const { reportPeriod, setReportPeriod } = useContext(FilterContext)
  const [organizationId, setOrganizationId] = useState<string>(secureStorage.getItem('organizationId') || '')

  const userInfo = secureStorage.getItem('userInfo');

  const [statCardTitles, setStatCardTitles] = useState<any>([])

  const [statCardData, setStatCardData] = useState<any>([])
  const [aeSectionData, setAeSectionData] = useState<any>([])
  const [aeNetEmissions, setAeNetEmissions] = useState<{ value: number, change: number }>({ value: 0, change: 0 })
  const [aeUnit, setAeUnit] = useState<string>("tCO2e")
  const [pcafUnit, setPcafUnit] = useState<string>("")
  const [climateRiskScoreData, setClimateRiskScoreData] = useState<any>(null)
  const [carbonRemovalsData, setCarbonRemovalsData] = useState<any>(null);
  const [carbonRemovalsUnit, setCarbonRemovalsUnit] = useState("");
  const [feData, setFeData] = useState<any>(null);

  const [showPrTableModal, setShowPrTableModal] = useState<boolean>(false)

  // loading states
  const [statCardsLoading, setStatCardsLoading] = useState<boolean>(false)
  const [aeLoading, setAeLoading] = useState<boolean>(false)
  const [carbonRemovalsLoading, setCarbonRemovalsLoading] = useState<boolean>(false)
  const [feLoading, setFeLoading] = useState<boolean>(false)
  const [climateRiskScoreLoading, setClimateRiskScoreLoading] = useState<boolean>(false)

  // ONLY BANK VIEW WILL SHOW FINANCED EMISSIONS
  const showFinancedEmissions = filterStore.entityLevel === EntityLevel.ORGANIZATION

  const colors = ["#1d62f4", "#1d2f53", "#15f5ba"]

  useEffect(() => {
    const FAKE_TITLE_DATA = {
      status: "success",
      data: {
        "ae": {
          "title": "Absolute Emissions",
          "description": "Absolute emissions refer to the total quantity of greenhouse gases (GHGs) released into the atmosphere as a result of all farming activities, without adjusting for variables like production levels or area size. These emissions include gases such as carbon dioxide (CO₂), methane (CH₄), and nitrous oxide (N₂O)."
        },
        "fe": {
          "title": "Financed Emissions",
          "description": "Financed emissions refer to greenhouse gas emissions linked to agricultural activities funded by financial institutions. These include emissions from farming operations, supply chains, and land-use changes, indirectly supported through loans, investments, or insurance."
        },
        "carbonRemovals": {
          "title": "Carbon Stock Summary",
          "description": "A carbon stock summary assesses the amount of carbon stored in soil, crops, and vegetation on agricultural land. It helps evaluate farming practices' contributions to carbon sequestration and overall climate impact."
        },
        "riskScore": {
          "title": "Climate Risk Score",
          "description": "The Climate Risk Score measures the potential impact of climate factors, such as temperature, rainfall, and extreme weather on crop yields and farm productivity, helping farmers assess vulnerabilities and plan for resilience."
        },
      }
    }

    // getDashboardData({
    //   force_refresh: false,
    //   include_trajectory: false,
    //   climateScenario: ClimateScenario.CURRENT,
    //   reportPeriod: "" + reportPeriod,
    //   entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    // }, filterStore.entityLevel, "titles")
    //   .then((data: any) => {
    //     // console.log(data)
    //     setStatCardTitles(data)
    //   })
    //   // .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
    //   .catch(() => {
    //     setStatCardTitles(FAKE_TITLE_DATA.data)
    //   })
    //   .finally(() => setStatCardsLoading(false))

    setStatCardTitles(FAKE_TITLE_DATA.data)

    if (!reportPeriod || reportPeriod === 0) return;

    // Get Dashboard Stat Cards
    setStatCardsLoading(true)
    getDashboardData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriod,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "cards")
      .then((data: any) => {
        // console.log(data)
        setStatCardData(data.data)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setStatCardsLoading(false))

    if (showFinancedEmissions) {
      // Get FE Data
      setFeLoading(true)
      getDashboardData({
        force_refresh: false,
        include_trajectory: false,
        climateScenario: ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, filterStore.entityLevel, "fe")
        .then((data: any) => {
          // console.log(data)
          setFeData(data.data)
          setPcafUnit(data.data.PCAF.unit)
        })
        .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
        .finally(() => setFeLoading(false))
    }

    // CARBON REMOVALS
    setCarbonRemovalsLoading(true)
    getDashboardData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriod,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "lu")
      .then((data: any) => {
        setCarbonRemovalsData(data.data);
        setCarbonRemovalsUnit(data.data?.unit || "");
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setCarbonRemovalsLoading(false))

    // Climate Risk
    setClimateRiskScoreLoading(true)
    getDashboardData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriod,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "climate_risk_score_deciles")
      .then((data) => {
        // console.log(data)
        const currentRiskValue = data.data.ClimateRiskScore.value;
        // @ts-ignore
        let currentRiskLevel = data.data.ClimateRiskScore.RiskLevel;
        const riskCategoryData = data.data.RiskCategory

        if (!currentRiskLevel && riskCategoryData) {
          for (const riskLevel of riskCategoryData) {
            for (const decile of riskLevel.Deciles) {
              if (decile.rangeStart <= currentRiskValue && decile.rangeEnd > currentRiskValue) {
                // @ts-ignore
                data.data.ClimateRiskScore.riskLevel = riskLevel.RiskLevel
              }
            }
          }
        }

        setClimateRiskScoreData(data.data)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setClimateRiskScoreLoading(false))

    // Get Absolute Emissions Data
    setAeLoading(true)
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + reportPeriod,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "emissions", "scope")
      .then((data) => {
        let rawData = data.data

        if ("data" in rawData) {
          rawData = rawData.data
        }

        const fyData = rawData.find((data: any) => +data.FY === reportPeriod)
        const scopeData: { title: string, value: number, change: number, color: string }[] = []

        if (fyData) {
          Object.keys(fyData)
            .filter((key: string) => (key.startsWith("Scope") || ["NetEmissions", "GrossEmission", "CarbonRemovals"].includes(key)))
            .forEach((key: string, keyIndex: number) => {
              scopeData.push({
                title: FormatKeyToLabel(key),
                value: +fyData[key].value,
                change: ParseChangeValues(fyData[key].change),
                color: key.startsWith("Scope") ? colors[keyIndex] : ""
              })
            })

          setAeNetEmissions(fyData["NetEmissions"])
        }
        // setAeUnit(fyData["NetEmissions"].unit)
        setAeSectionData(scopeData)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setAeLoading(false))
  }, [reportPeriod, showFinancedEmissions, filterStore.entityIds, filterStore.entityLevel, filterStore.searchType, filterStore.searchedSectors, filterStore.searchedRegions])

  return (
    <Layout >
      <Box className="flex flex-col gap-4">
        <Box className="flex justify-between items-center w-full">
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>Welcome, {userInfo?.displayName}</Typography>
          <CriteriaHeader breadCrumb={"Dashboard"} chipsOnly={true} />
        </Box>

        {/* FILTER ROW */}
        <Box className="border rounded-md p-4 py-2 bg-gray-100 flex justify-between ">
          <Box className="flex gap-4 items-center">
            <p className='font-bold'>Report Period</p>
            <VmDropdown label="" value={reportPeriod} onChange={setReportPeriod} options={filterStore.fyOptions} />
          </Box>
        </Box>

        <Box className="grid gap-4" style={{ gridTemplateColumns: `repeat(${statCardData.length}, minmax(0, 1fr))` }}>
          {
            statCardsLoading
              ? [1, 1, 1, 1].map((_, index: number) => (
                <Skeleton key={index} variant="rectangular" height={200} animation="wave" className="rounded-lg" />
              ))
              : statCardData && statCardData.length !== 0 && statCardData.map((card: any, index: number) => {
                return card.name
                  ? <DashboardStatCard key={index} title={card.name} value={card.value} unit={card.unit} />
                  : null;
              })
          }
        </Box>

        <Box className="grid grid-cols-4 gap-4">

          {/* AE CARD */}
          <Box className="border col-span-2 rounded-lg p-4 bg-white min-h-60">
            <Box className="flex justify-between items-start">
              <Box className="flex flex-col gap-2">
                <Box className="flex items-center gap-2">
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>{statCardTitles?.ae?.title}</Typography>
                  <VmHelp>{statCardTitles?.ae?.description}</VmHelp>
                </Box>
                <p className=" text-sm">{aeUnit}</p>
              </Box>

              <button onClick={() => navigate({
                pathname: `/summary`,
                search: `?${createSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  lv1: "" + FilterLevelOne.AE,
                  lv2: "" + FilterLevelTwo.AE_EMISSIONS,
                  lv3: "" + FilterLevelThree.AE_EMISSION_EMISSION_SUMMARY
                })}`
              })}>
                <p className="text-lg" style={{ color: theme.palette.primary.main }}>View More <ArrowForwardIos sx={{ fontSize: 16 }} /></p>
              </button>
            </Box>

            <Box className="grid grid-cols-5 gap-2 w-full">
              <Box className="col-span-2 relative" sx={{ height: ChartHeight }}>
                {
                  aeLoading
                    ? <Skeleton variant="circular" height="100%" sx={{ aspectRatio: "1/1", margin: "auto" }} animation="pulse" />
                    : <DashboardPieChart series={
                      aeSectionData
                        .filter((data: any) => data.title.startsWith("Scope"))
                        .map((data: any) => ({
                          // name: data.title,
                          value: data.value,
                          itemStyle: {
                            normal: {
                              color: data.color
                            }
                          }
                        }))
                    } />
                }
                <Box className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex flex-col items-center justify-center">
                  {
                    aeLoading
                      ? <>
                        <Skeleton variant="text" width="5rem" height="2rem" animation="pulse" />
                        <Skeleton variant="text" width="7rem" height="2rem" animation="pulse" />
                      </>
                      : <ValueStatCard title="Gross Emissions" value={aeNetEmissions.value ? aeNetEmissions.value : "--"} changeAmount={ParseChangeValues(aeNetEmissions.change)} background='none' />
                  }
                </Box>
              </Box>

              <Box className="grid grid-cols-2 col-span-3 items-center gap-2 bg-[#f7f9fd] p-2 rounded-lg w-full">
                {
                  aeSectionData.map((data: any, i: number) => (
                    aeLoading
                      ? <Skeleton variant="rectangular" width="100%" height="100%" animation="pulse" className='col-span-2' />
                      : <>
                        <Box className="flex gap-2 items-center justify-start">
                          <Box className="w-3 h-3 rounded-sm" style={{ background: data.color }} />
                          <p>{data.title}</p>
                        </Box>
                        <Box className="flex gap-2 items-center justify-end">
                          <p className="text-sm font-bold text-end">{data.value.toLocaleString()}</p>
                          <ChangesLabel changeAmount={+data.change} />
                        </Box>
                      </>
                  ))
                }
              </Box>
            </Box>
          </Box>

          {/* FE CARD */}
          {
            showFinancedEmissions && <Box className="border col-span-2 rounded-lg p-4 bg-white min-h-60">
              <Box className="flex justify-between items-start">
                <Box className="flex flex-col gap-2">
                  <Box className="flex items-center gap-2">
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>{statCardTitles?.fe?.title}</Typography>
                    <VmHelp>{statCardTitles?.fe?.description}</VmHelp>
                  </Box>
                  <p className=" text-sm">{pcafUnit}</p>
                </Box>

                <button onClick={() => navigate({
                  pathname: `/summary`,
                  search: `?${createSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    lv1: "" + FilterLevelOne.FINANCED,
                    lv2: "" + FilterLevelTwo.FINANCED_EMISSIONS,
                    lv3: "" + FilterLevelThree.FINANCED_EMISSION_SCOPE
                  })}`
                })}>
                  <p className="text-lg" style={{ color: theme.palette.primary.main }}>View More <ArrowForwardIos sx={{ fontSize: 16 }} /></p>
                </button>
              </Box>

              <Box className="flex gap-4 w-full">
                <Box sx={{ height: ChartHeight }}>
                  {
                    feLoading
                      ? <Skeleton variant="rectangular" width="100%" height="100%" animation="pulse" className='col-span-2' />
                      : feData && <ValueStatCard title="PCAF Score" value={feData.PCAF?.value} changeAmount={ParseChangeValues(feData.PCAF?.change)} unit="out of 10" className=' h-full px-8' />
                  }
                </Box>

                <Box className="flex-1" sx={{ height: ChartHeight }}>
                  {
                    feLoading
                      ? <Skeleton variant="rectangular" width="100%" height="100%" animation="pulse" className='col-span-2' />
                      : feData && <DashbaordBarChart
                        series={Object.keys(feData)
                          .filter((key: any) => !["PCAF"].includes(key))
                          .map((key: any) => ({
                            title: FormatKeyToLabel(key),
                            value: feData[key].value,
                          }))}
                        height={ChartHeight}
                      />
                  }
                </Box>
              </Box>
            </Box>
          }

          {/* Carbon Removals CARD */}
          <Box className="border rounded-lg col-span-2 p-4 bg-white min-h-60">
            <Box className="flex justify-between items-start">
              <Box className="flex flex-col gap-2">
                <Box className="flex items-center gap-2">
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>{statCardTitles?.carbonRemovals?.title}</Typography>
                  <VmHelp>{statCardTitles?.carbonRemovals?.description}</VmHelp>
                </Box>
                <p className=" text-sm">{carbonRemovalsUnit}</p>
              </Box>

              <button onClick={() => navigate({
                pathname: `/summary`,
                search: `?${createSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  lv1: "" + FilterLevelOne.LAND_USE_AND_REMOVALS,
                  lv2: "" + FilterLevelTwo.LAND_USE_LAND_USE,
                  lv3: "" + FilterLevelThree.LAND_USE_SUMMARY
                })}`
              })}>
                <p className="text-lg" style={{ color: theme.palette.primary.main }}>View More <ArrowForwardIos sx={{ fontSize: 16 }} /></p>
              </button>
            </Box>

            <Box className="grid grid-cols-3 grid-rows-2 gap-4 bg-white min-h-60">
              <>
                {
                  carbonRemovalsLoading
                    ? <Skeleton variant="rectangular" width="100%" height="100%" animation="pulse" className='row-span-2' />
                    : carbonRemovalsData && <ValueStatCard title={"Total"} value={carbonRemovalsData.total.value ? carbonRemovalsData.total.value.toFixed() : "--"} changeAmount={ParseChangeValues(carbonRemovalsData.total.change)} className={"row-span-2"} />
                }
                {
                  carbonRemovalsLoading
                    ? <>
                      <Skeleton variant="rectangular" width="100%" height="100%" animation="pulse" />
                      <Skeleton variant="rectangular" width="100%" height="100%" animation="pulse" />
                      <Skeleton variant="rectangular" width="100%" height="100%" animation="pulse" />
                      <Skeleton variant="rectangular" width="100%" height="100%" animation="pulse" />
                    </>
                    : carbonRemovalsData && Object.keys(carbonRemovalsData)
                      .filter((key: any) => !["total", "unit", ""].includes(key))
                      .map((key: any, i: number) => {
                        const data = carbonRemovalsData[key];
                        return <ValueStatCard key={`carbon_removals_${i}`} title={FormatKeyToLabel(key)} value={data.value ? (+data.value).toFixed() : "--"} changeAmount={ParseChangeValues(data.change)} className={""} />
                      })
                }
              </>
            </Box>
          </Box>

          {/* Climate Risk Score CARD */}
          <Box className={`grid ${!showFinancedEmissions ? "col-span-3" : "col-span-1"} gap-4 h-[400px]`}>
            <Box className="border rounded-lg p-4 bg-white w-full ">
              <Box className="flex items-center gap-2">
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>{statCardTitles?.riskScore?.title}</Typography>
                <VmHelp>{statCardTitles?.riskScore?.description}</VmHelp>
              </Box>

              <Box className={`grid ${!showFinancedEmissions ? "grid-cols-3" : "grid-cols-1"} gap-4 h-full overflow-auto -mt-6`}>
                <Box className=" flex items-center justify-center">
                  {
                    climateRiskScoreData && <PRGradientStatsCircle
                      title="Climate Risk Score"
                      value={climateRiskScoreData.ClimateRiskScore.value}
                      changeAmount={ParseChangeValues(climateRiskScoreData.ClimateRiskScore.change)}
                      riskLevel={climateRiskScoreData.ClimateRiskScore.riskLevel}
                      className={`h-[270px] `}
                      onClickViewTableButton={showFinancedEmissions ? (() => setShowPrTableModal(true)) : undefined}
                    />
                  }
                </Box>
                {
                  !showFinancedEmissions && climateRiskScoreData && <Box className="col-span-2 m-auto">
                    <PrDecileTable data={climateRiskScoreData.RiskCategory} isHorizontal={true} />
                  </Box>
                }
              </Box>
            </Box>
          </Box>

          {/* Quick Link CARD */}
          <Box className="border rounded-lg p-4 bg-white min-h-60 flex flex-col gap-4">
            <Box className="flex justify-between items-start">
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>Quick Link</Typography>
            </Box>

            <Box className=" flex flex-col gap-2 my-auto">
              <QuickLinkButton
                disabled={true}  // TODO: enable this if data is ready
                label='Physical Risk'
                onClick={() => navigate({
                  pathname: `/summary`,
                  search: `?${createSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    lv1: "" + FilterLevelOne.PHYSICAL_RISK,
                    lv2: "" + FilterLevelTwo.PHYSICAL_RISK_ACUTE,
                    lv3: "" + FilterLevelThree.PHYSICAL_RISK_ACUTE_DROUGHT
                  })}`
                })} />
              {
                filterStore.entityLevel === EntityLevel.ORGANIZATION && <QuickLinkButton label='Owner list' onClick={() => navigate({
                  pathname: `/owners`,
                  search: `?${createSearchParams(searchParams)}`
                })} />
              }
              {
                (filterStore.entityLevel === EntityLevel.ORGANIZATION || filterStore.entityLevel === EntityLevel.ASSET_OWNER) && <QuickLinkButton label='Asset list' onClick={() => navigate({
                  pathname: `/assets`,
                  search: `?${createSearchParams(searchParams)}`
                })} />
              }
            </Box>
          </Box>
        </Box>
      </Box>

      <VmModal open={showPrTableModal} onClose={() => setShowPrTableModal(false)} height="450px">
        <Box className="flex  justify-between items-center">
          <Typography variant='h5' sx={{ fontWeight: "bold" }} className='z-10'>Climate Risk Score</Typography>
          <IconButton onClick={() => setShowPrTableModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <Box className="flex gap-4 items-center mt-4">
          {
            climateRiskScoreData && <PRGradientStatsCircle
              title="Climate Risk Score"
              value={climateRiskScoreData.ClimateRiskScore.value}
              changeAmount={ParseChangeValues(climateRiskScoreData.ClimateRiskScore.change)}
              riskLevel={climateRiskScoreData.ClimateRiskScore.riskLevel}
              className={`h-[250px]`}
              onClickViewTableButton={showFinancedEmissions ? (() => setShowPrTableModal(true)) : undefined}
            />
          }
          {climateRiskScoreData && <PrDecileTable data={climateRiskScoreData.RiskCategory} isHorizontal={true} />}
        </Box>
      </VmModal>
    </Layout >
  );
};

const QuickLinkButton = ({ label, onClick, disabled }: { label: string, onClick: () => any, disabled?: boolean }) => {

  return (
    <Tooltip title={disabled ? "Data not ready" : ""}>
      <button onClick={disabled ? () => { } : onClick}>
        <Box className={`flex items-center justify-between gap-2 bg-[#f7f9fd] p-2 rounded-lg ${disabled ? "cursor-not-allowed opacity-50 grayscale" : ""}`}>
          <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "18px" }}>{label}</Typography>
          <Box className="w-12 h-12 flex items-center justify-center rounded-md" sx={{ background: "#0061ff", color: 'white' }}>
            <ArrowForwardIos sx={{ fontSize: 20 }} />
          </Box>
        </Box>
      </button>
    </Tooltip>
  )
}

const DashboardStatCard = ({ title, value, unit }: { title: string, value: number | string, unit: string }) => {
  return (
    <Box className="flex flex-col gap-1 border rounded-lg p-4 items-center bg-white ">
      <p className=" opacity-70">{title}</p>
      <Typography variant="h3" component={'span'} color='primary' sx={{ fontWeight: "bold" }}>{value}</Typography>
      <p className=" opacity-70">{unit}</p>
    </Box>
  );
};

const ValueStatCard = ({ title, value, changeAmount, unit, className, background = "#f7f9fd" }: { title: string, value: number | string, changeAmount: number, className?: string, unit?: string, background?: string }) => {

  return (
    <Box className={`rounded-lg p-2 text-center flex w-full flex-col items-center px-4 justify-center gap-1 ${className}`} sx={{ background: background }}>
      <p>{title}</p>
      <p className="text-[1.2rem] 2xl:text-[1.6rem] font-bold">{value ? (+value).toLocaleString() : "--"}</p>
      <ChangesLabel changeAmount={changeAmount} />
      {unit && <p className="text-sm opacity-70">{unit}</p>}
    </Box>
  )
}

export default observer(SummaryDashboardPage);
