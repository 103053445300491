import React, { useContext, useEffect, useRef, useState } from 'react'
import RegionDataTable from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData, getOrganizationLandUseData } from '../../../api/explorerService'
import { ClimateScenario, EntityLevel, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import { FormatFYChartTitle, FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions, GetMinYAxisValue, GetScenarioName } from '../../../utils/general'
import { Box, colors, IconButton, Typography } from '@mui/material'
import SunburstChart from '../../charts/sunburst-chart'
import { defaultDataKeyColours, lerp, lerpColor, RandomRGB } from '../../../utils/colour'
import { FilterContext } from '../../../context/filter-bar-context'
import SimpleLineChart from '../../charts/simple-line-chart'
import { Close } from '@mui/icons-material'
import VmModal from '../../VmModal'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import { FilterLevelThree, FilterLevelTwo } from '../../summary/filter-section'

import { APIErrorHandler } from '../../../utils/general'
import { FilterStoreContext } from '../../../context/filter-store-context'
import BannerText from '../../BannerText'
import { useSnackbar } from 'notistack'

const SummaryRemovalsLandUseTypeSection = observer(({ levelThreeId }: { levelThreeId: FilterLevelThree }) => {
  const { reportPeriod, setCompareToYears, setAllowedCompareToYears, compareToYears, } = useContext(FilterContext)
  const filterStore = useContext(FilterStoreContext);
  const { enqueueSnackbar } = useSnackbar();
  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])

  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [selectedClimateScenario, setSelectedClimateScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)

  const [unit, setUnit] = useState<string>("")

  const [loading, setLoading] = useState<boolean>(false)
  const [climateChangeLoading, setClimateChangeLoading] = useState<boolean>(false)

  const [fyOptions, setFyOptions] = useState<any[]>([])
  const [lineSeriesDataWithFy, setLineSeriesDataWithFy] = useState<any[]>([])

  const [climateChangeModalFyOptions, setClimateChangeModalFyOptions] = useState<any[]>([])
  const [climateChangeModalLineSeriesDataWithFy, setClimateChangeModalLineSeriesDataWithFy] = useState<any[]>([])
  const [climateChangeOnlyShowTotal, setClimateChangeOnlyShowTotal] = useState<boolean>(false)

  const dataKeyColours = useRef<{ [key: string]: string }>(defaultDataKeyColours)

  const refreshChartData = (fy: number = reportPeriod) => {
    const levelTwoParamStr = levelThreeId === FilterLevelThree.LAND_USE_REMOVALS_CARBON_STOCK_TYPE ? "removals" : "landuse"
    const levelThreeParamStr = levelThreeId === FilterLevelThree.LAND_USE_REMOVALS_CARBON_STOCK_TYPE ? "carbon_stock_type" : "land_use_type"

    setLoading(true)
    getOrganizationLandUseData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, levelTwoParamStr, levelThreeParamStr)
      .then((data) => {
        let rawData = data.data;
        if ("data" in rawData) rawData = rawData.data

        if (!Array.isArray(rawData)) {
          // Handle case where rawData is in wrong format
          setChartData([]);
          return;
        }

        rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)
        rawData = rawData.filter((a: any) => a.FY !== "");

        if (rawData && rawData.length === 0) { setChartData([]); return }

        if (rawData.length > 0) {
          const firstFy = rawData[0].FY
          setAllowedCompareToYears(fy - (+firstFy))
          setCompareToYears(0)
        }

        let fyData = rawData.find((data: any) => +data.FY === fy)
        if (!fyData) { setChartData([]); return }

        setUnit(fyData.total.unit)

        const allKeys = Object.keys(fyData).filter((key: string) => !["FY", "total", ""].includes(key))
        let processedData: any[] = []

        allKeys
          .map((key: string) => {
            let mainColor = dataKeyColours.current[key] || RandomRGB();

            let tempData = {
              ...fyData[key],
              name: FormatKeyToLabel(key),
              itemStyle: {
                color: mainColor
              },
              children: (fyData[key] && fyData[key].children && fyData[key].children.length > 0)
                ? fyData[key].children.map((child: any, childIndex: number) => {
                  let childNode = {}
                  const totalChildrenCount = fyData[key].children.length
                  Object.keys(child)
                    .filter((childKey: any, index: number) => index === 0)
                    .map((childKey: any) => {
                      childNode = {
                        ...child[childKey],
                        name: FormatKeyToLabel(childKey),
                        itemStyle: {
                          color: lerpColor(mainColor, "#FFFFFF", childIndex / totalChildrenCount)
                          // color: `${mainColor}99`
                        }
                      };
                    })

                  return { ...childNode, }
                })
                : []
            }

            processedData.push(tempData)
          })

        setChartData(processedData)

        if (rawData.length > 1) {
          setFyOptions(rawData.map((fyData: any) => fyData.FY))
          let tempSeriesData: any = {};

          const dataKeys = allKeys.filter((key: string) => !["FY", "total"].includes(key))
          for (const key of dataKeys) {
            if (!dataKeyColours.current[key]) {
              dataKeyColours.current[key] = RandomRGB();
            }
          }

          rawData.map((fyData: any, fyIndex: number) => {
            allKeys
              .filter((key: any) => !["FY", "total"].includes(key))
              .map((key: string) => {
                let mainColor = dataKeyColours.current[key] || RandomRGB();

                const keyLabel = FormatKeyToLabel(key)

                const processedValue = {
                  value: fyData[key].value,
                  fy: fyData.FY,
                  color: mainColor
                }

                if (fyIndex === 0 || !tempSeriesData[keyLabel]) {
                  tempSeriesData[keyLabel] = [processedValue]
                } else {
                  tempSeriesData[keyLabel].push(processedValue)
                }

                if (fyData[key].children && fyData[key].children.length > 0) {
                  fyData[key].children.map((child: any, childIndex: number) => {
                    const childKey = `${keyLabel} - ${FormatKeyToLabel(Object.keys(child)[0])}`

                    const processedChildValue = {
                      value: child[Object.keys(child)[0]].value,
                      fy: fyData.FY,
                      color: lerpColor(mainColor, "#FFFFFF", childIndex / fyData[key].children.length)
                    }

                    if (fyIndex === 0 || !tempSeriesData[childKey]) {
                      tempSeriesData[childKey] = [processedChildValue]
                    } else {
                      tempSeriesData[childKey].push(processedChildValue)
                    }
                  })
                }
              })
          })

          let parsedLineSeriesData: any[] = []

          Object.keys(tempSeriesData).map((key: any) => {
            parsedLineSeriesData.push({
              name: key,
              data: tempSeriesData[key],
              itemStyle: {
                color: tempSeriesData[key][0].color
              }
            })
          })
          setLineSeriesDataWithFy(parsedLineSeriesData)
        }
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setLoading(false))
  }

  const handleOpenStressTestModal = async () => {
    setShowClimateChangeModal(true);
    setClimateChangeLoading(true);

    const levelTwoParamStr = levelThreeId === FilterLevelThree.LAND_USE_REMOVALS_CARBON_STOCK_TYPE ? "removals" : "landuse";
    const levelThreeParamStr = levelThreeId === FilterLevelThree.LAND_USE_REMOVALS_CARBON_STOCK_TYPE ? "carbon_stock_type" : "land_use_type";

    let climateChangeChartData: any[] = [];
    let climateChangeFYOptions: any[] = [];

    Promise.all(
      [ClimateScenario.CURRENT, ClimateScenario.DISORDERLY].map((climateScenario: ClimateScenario) => {
        return getOrganizationLandUseData({
          force_refresh: false,
          include_trajectory: true,
          climateScenario: climateScenario,
          reportPeriod: "" + reportPeriod,
          entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
        }, filterStore.entityLevel, levelTwoParamStr, levelThreeParamStr)
          .then((data: any) => {
            let rawData = data.data;
            if ("data" in rawData) rawData = rawData.data
            rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

            if (climateChangeFYOptions.length === 0) {
              climateChangeFYOptions = rawData.map((data: any) => data.FY)
            }

            if (rawData.length === 0) return;

            const dataKeys = Object.keys(rawData[0])
              .filter((key: string) => !["FY"].includes(key));

            for (const key of dataKeys) {
              if (!dataKeyColours.current[key]) {
                dataKeyColours.current[key] = RandomRGB();
              }
            }

            dataKeys.forEach((key: any) => {
              climateChangeChartData.push({
                name: `${FormatKeyToLabel(key)} - ${GetScenarioName(climateScenario)}`,
                type: 'line',
                data: rawData.map((data: any) => ({
                  ...data[key],
                  fy: data.FY,
                  climateScenario: climateScenario,
                })),
                climateScenario: climateScenario,
                itemStyle: {
                  color: dataKeyColours.current[key] || RandomRGB()
                },
                isTotal: key.toLowerCase() === "total",
              })
            })
          })
          .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      })
    ).then(() => {
      climateChangeChartData.sort((a: any, b: any) => a.name.localeCompare(b.name))
      
      setClimateChangeModalLineSeriesDataWithFy(climateChangeChartData)
      setClimateChangeModalFyOptions(climateChangeFYOptions)
    })
      .finally(() => setClimateChangeLoading(false))
  }

  const handleExportData = () => {
    const levelTwoParamStr = levelThreeId === FilterLevelThree.LAND_USE_REMOVALS_CARBON_STOCK_TYPE ? "removals" : "landuse"
    const levelThreeParamStr = levelThreeId === FilterLevelThree.LAND_USE_REMOVALS_CARBON_STOCK_TYPE ? "carbon_stock_type" : "land_use_type"

    exportFile(
      GenerateExportUrl("lu", levelTwoParamStr, levelThreeParamStr),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Land Use and Removals - Summary.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
  }

  useEffect(() => {
    refreshChartData()
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={handleOpenStressTestModal}
        allowNoCompareToYears={true}
        changeReportPeriodCallBack={refreshChartData}
        onClickExport={handleExportData}
        showExport={filterStore.entityLevel === EntityLevel.ASSET}
        showStressTestToggle={levelThreeId === FilterLevelThree.LAND_USE_REMOVALS_CARBON_STOCK_TYPE}
        showBenchmarkToggle={false}
        showTargetToggle={false}
      />

      {
        loading
          ? <BannerText text={"Loading..."} />
          : <Box className=" flex justify-center gap-8 h-[550px]">
            {
              compareToYears === 0
                ? <>
                  {
                    chartData && chartData.length > 0
                      ? <>
                        <Box className="w-[500px] h-full">
                          <SunburstChart data={chartData} />
                        </Box>
                        <Box className=" flex flex-col justify-center items-start h-fit w-fit bg-gray-200 p-2 rounded-md my-auto">
                          <LandUseTypeTable chartData={chartData} unit={unit} />
                        </Box>
                      </>
                      : <BannerText text={"No Data Found"} subText={"Please try different filters or choose a different year"} />
                  }
                </>
                : <>
                  <Box className="w-full">
                    {
                      fyOptions && lineSeriesDataWithFy && lineSeriesDataWithFy.length > 0
                        ? <SimpleLineChart
                          yAxisTitle={unit}
                          titles={fyOptions.filter((fy: number) => fy >= reportPeriod - compareToYears && fy <= reportPeriod).map(FormatFYChartTitle)}
                          series={lineSeriesDataWithFy.map((data: any) => ({
                            ...data,
                            type: "line",
                            data: data.data
                              .filter((value: any) => +value.fy >= reportPeriod - compareToYears && +value.fy <= reportPeriod)
                              .map((value: any) => {
                                return {
                                  ...value,
                                  itemStyle: {
                                    color: value.color
                                  },
                                  value: value.value,
                                  symbol: 'roundRect',
                                  symbolSize: 20,
                                }
                              })
                          }))}
                        />
                        : <BannerText text={"No Data Found"} subText={"Please try different filters or choose a different year"} />
                    }
                  </Box>
                </>
            }
          </Box>
      }

      <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
        <Box className="flex items-center justify-between">
          <Typography variant="h6">Stress Test</Typography>
          <IconButton onClick={() => setShowClimateChangeModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <ClimateChangeToggleBar
          selectedClimateScenario={selectedClimateScenario}
          setSelectedClimateScenario={setSelectedClimateScenario}
          setShowTotalOnly={setClimateChangeOnlyShowTotal}
          showTotalOnly={climateChangeOnlyShowTotal}
        />
        <SimpleLineChart
          key={`climate-change-modal-line-chart-${selectedClimateScenario}-${climateChangeOnlyShowTotal}`}
          yAxisTitle={unit}
          animation={false}
          loading={climateChangeLoading}
          titles={climateChangeModalFyOptions.filter((fy: number) => fy >= reportPeriod).map(FormatFYChartTitle)}
          // minYAxisValue={GetMinYAxisValue(climateChangeModalLineSeriesDataWithFy.filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true))}
          series={climateChangeModalLineSeriesDataWithFy
            .filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true)
            .map((data: any) => ({
              ...data,
              type: "line",
              symbol: selectedClimateScenario === data.climateScenario ? 'roundRect' : 'triangle',
              symbolSize: 20,
              lineStyle: {
                width: 2,
                type: selectedClimateScenario === data.climateScenario ? "solid" : "dashed",
                opacity: selectedClimateScenario === data.climateScenario ? 1 : 0.2,
                color: data.color
              },
              data: data.data
                .filter((value: any) => +value.fy >= reportPeriod)
                .map((value: any) => {
                  return {
                    ...value,
                    itemStyle: {
                      color: value.color,
                      opacity: selectedClimateScenario === value.climateScenario ? 1 : 0.4,
                    },
                    value: value.value,
                  }
                })
            }))}
        />
      </VmModal>
    </>
  )
})

const LandUseTypeTable = observer(({ chartData, unit }: { chartData: any, unit: string }) => {
  return <Box className=" flex flex-col justify-center items-start h-fit w-fit bg-gray-200 p-2 rounded-md my-auto">
    {
      chartData && <table className="">
        <thead>
          <tr>
            <th className="p-2 ">Land Use Type</th>
            <th className="p-2 text-right">{unit || ""}</th>
          </tr>
        </thead>
        <tbody>
          {
            chartData && chartData.length > 0 && chartData
              .map((rowData: any) => (
                <>
                  <tr>
                    <td className="p-2">
                      <div className="flex items-center gap-2">
                        {rowData.itemStyle && <div className="w-4 h-4 rounded-sm" style={{ backgroundColor: rowData.itemStyle.color }}></div>}
                        <span>{rowData.name}</span>
                      </div>
                    </td>
                    <td className="p-2 text-right">{Math.round(rowData?.value)?.toLocaleString()}</td>
                  </tr>
                  {
                    rowData.children && rowData.children.length > 0 && rowData.children
                      .map((child: any) => (
                        <tr>
                          <td className="p-2 pl-6">
                            <div className="flex items-center gap-2">
                              {child.itemStyle && <div className="w-4 h-4 rounded-sm" style={{ backgroundColor: child.itemStyle.color }}></div>}
                              <span>{child.name}</span>
                            </div>
                          </td>
                          <td className="p-2 text-right">{Math.round(child?.value)?.toLocaleString()}</td>
                        </tr>
                      ))
                  }
                </>
              ))
          }
        </tbody>
      </table>
    }
  </Box>
})

export default SummaryRemovalsLandUseTypeSection
