import React, { useContext, useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Checkbox, Collapse, CssBaseline, Drawer, FormControlLabel, IconButton, InputBase, ListItemIcon, Menu, MenuItem, Radio, RadioGroup, Snackbar, TextField, Toolbar } from '@mui/material';
import NavBar from '../components/NavBar'; // Import the NavBar component
import SideBar from '../components/Sidebar'; // Import the new SideBar component
import { GridView, People, Gite, Search, Close, SubdirectoryArrowRight, LocationOn, KeyboardArrowDown, Sort, Logout } from '@mui/icons-material';
import CriteriaHeader from './criteria-header';
import Logo from '../images/wollemai_logo.svg';

// Router
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

// MOBX
import { observer } from 'mobx-react-lite';
import { getRegionOptions, getSectorOptions, getYearOptions, searchEntityList } from '../api/explorerService';
import { ClimateScenario, EntityLevel, OrganizationTypes, SearchType } from '../utils/options';
import { FilterContext } from '../context/filter-bar-context';
import { SetLocalStorage } from '../utils/cryptography';
import { GetLocalStorage } from '../utils/cryptography';
import moment from 'moment';
import { APIErrorHandler, DeepCopy, FormatKeyToLabel, GetCurrentFY } from '../utils/general';

// Images
import SectorIcon from '../images/sector_farm_icon.svg';
import SecureStorage from '../services/secureStorage';
import { useSnackbar } from 'notistack'

import { FilterStoreContext } from '../context/filter-store-context';

enum DisplayPageTypes { DASHBOARD, SUMMARY, OWNERS, ASSETS, OTHERS };

interface RegionNode {
  id: string;
  name: string;
  level: number;
  systemLevel: "STATE" | "SA4" | "SA3" | "SA2" | "SA1" | "";
  parentId: string;
  isSelected: boolean;
  children: RegionNode[];
}

const OptionCacheExpire = 10;

const Layout = ({ placeHolder, onTriggerAfterSearch, children, onTriggerAfterCheckRegion }: { placeHolder?: string, onTriggerAfterSearch?: (search: { assetOwnerId: any, assetId: any }) => any, onTriggerAfterCheckRegion?: (searchType: SearchType, selectedRegions: any[], selectedSectors: any[]) => any, children: any }) => {
  const filterStore = useContext(FilterStoreContext);
  const { reportPeriod, setReportPeriod } = useContext(FilterContext)
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const secureStorage = SecureStorage.getInstance();
  const userInfo = secureStorage.getItem('userInfo');
  const userType: OrganizationTypes = userInfo?.organization_type;
  const navigate = useNavigate();
  const browserUrl = window.location.pathname;
  const [searchKey, setSearchKey] = useState<string>("");
  const [userAnchor, setUserAnchor] = useState<null | HTMLElement>(null);
  const [openQuickSearch, setOpenQuickSearch] = useState<boolean>(false);

  const [expandedRegions, setExpandedRegions] = useState<string[]>([]);
  const [searchedEntityOptions, setSearchedEntityOptions] = useState<any[]>([]);

  const [localSearchType, setLocalSearchType] = useState<SearchType>(SearchType.REGION)
  const [selectedSearchEntity, setSelectedSearchEntity] = useState<any>(null);

  let links = [
    { path: "/summary/dashboard", label: "Summary", icon: <GridView color='primary' />, type: DisplayPageTypes.SUMMARY },
  ]

  // ONLY ORGANIZATION LEVEL SEES ASSETS
  if (filterStore.entityLevel === EntityLevel.ORGANIZATION) {
    links.push({ path: "/owners", label: "Owners", icon: <People color='primary' />, type: DisplayPageTypes.OWNERS })
  }

  // ONLY ASSET OWNER LEVEL SEES ASSETS
  if (filterStore.entityLevel === EntityLevel.ASSET_OWNER || filterStore.entityLevel === EntityLevel.ORGANIZATION) {
    links.push({ path: "/assets", label: "Assets", icon: <Gite color='primary' />, type: DisplayPageTypes.ASSETS })
  }

  const isActiveLink = (url: string) => {
    const matchString = url.split("/")[1];
    return Boolean(browserUrl.includes(matchString));
  };

  const onClickedSearchEntity = () => {
    let newEntityIds: any = DeepCopy(filterStore.entityIds)

    if (!selectedSearchEntity) {
      // CLEAR THE SELECTED REGIONS AND SECTORS
      return;
    }

    if (selectedSearchEntity.type === "asset_owner") {
      if (userType === OrganizationTypes.ASSET) {
        // DO NOTHING, THE USER IS AN ASSET OWNER
      } else {
        newEntityIds = {
          assetOwnerId: { id: selectedSearchEntity.id, name: selectedSearchEntity.label },
          assetId: null
        }
      }
    } else if (selectedSearchEntity.type === "asset") {
      newEntityIds = {
        ...newEntityIds,
        assetId: { id: selectedSearchEntity.id, name: selectedSearchEntity.label }
      }
    }

    if (onTriggerAfterSearch) onTriggerAfterSearch(newEntityIds);

    filterStore.entityIds = DeepCopy(newEntityIds)

    setSearchParams({
      ...searchParams,
      assetOwnerId: newEntityIds.assetOwnerId?.id || '',
      assetOwnerName: newEntityIds.assetOwnerId?.name || '',
      assetId: newEntityIds.assetId?.id || '',
      assetName: newEntityIds.assetId?.name || ''
    })

    const newLevel = selectedSearchEntity.type === "asset_owner" ? EntityLevel.ASSET_OWNER
      : selectedSearchEntity.type === "asset" ? EntityLevel.ASSET
        : EntityLevel.ORGANIZATION

    if (userType === OrganizationTypes.ASSET_OWNER && newLevel === EntityLevel.ORGANIZATION) {
      filterStore.entityLevel = EntityLevel.ASSET_OWNER
    } else if (userType === OrganizationTypes.ASSET && newLevel === EntityLevel.ORGANIZATION) {
      filterStore.entityLevel = EntityLevel.ASSET
    } else if (userType === OrganizationTypes.ASSET && newLevel === EntityLevel.ASSET_OWNER) {
      filterStore.entityLevel = EntityLevel.ASSET
    } else {
      filterStore.entityLevel = newLevel
    }

    searchEntityOptions("", newLevel)
  }

  const handleResetSelectedRegion = () => {
    const currentOptions = DeepCopy(filterStore.regionOptions);

    // recursive function to set the value
    const setFalse = (object: any) => {
      object.isSelected = false;

      if (object.children && object.children.length > 0) {
        object.children.forEach((child: any) => setFalse(child))
      } else {
        return;
      }
    }

    currentOptions.forEach((item: any) => setFalse(item))

    const newStatus = [...currentOptions]
    return newStatus
  }

  const signOut = () => {
    SecureStorage.getInstance().clear();
    SetLocalStorage('accessToken', '');
    navigate('/login');
    localStorage.clear();
    setReportPeriod(0)
  }

  const searchEntityOptions = (search: string, entityLevelForced: EntityLevel = filterStore.entityLevel) => {
    searchEntityList({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      search_term: search,
      financial_year: "" + reportPeriod
    }, entityLevelForced === EntityLevel.ORGANIZATION ? "asset_owner" : entityLevelForced === EntityLevel.ASSET_OWNER ? "asset" : "asset")
      .then((data: any) => {
        const options = data.data.items.map((item: any) => {
          return {
            // name: item.entity_name,
            id: item.id,
            label: item.entity_name,
            type: item.entity_type
          }
        })

        setSearchedEntityOptions(options)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
  }

  useEffect(() => {
    if (!reportPeriod || reportPeriod === 0) return;

    const debounce = setTimeout(() => {
      searchEntityOptions(searchKey)
    }, 500);
    return () => clearTimeout(debounce);
  }, [searchKey, reportPeriod])

  useEffect(() => {
    if (filterStore.regionOptions.length === 0 || filterStore.sectorOptions.length === 0) loadSectorRegionOptions();
    loadEntityIdFromParams();
  }, [])

  useEffect(() => {
    const cachedYearOptions = GetLocalStorage("yearOptions")
    if (cachedYearOptions && cachedYearOptions.expiry && cachedYearOptions.expiry > moment().unix()) {
      const value = cachedYearOptions.value
      filterStore.fyOptions = value
      setReportPeriod(value[value.length - 1].value)
    } else {
      getYearOptions({
        force_refresh: false,
        include_trajectory: false,
        climateScenario: ClimateScenario.CURRENT,
        report_period: "2000",
        // report_period: "" + moment().year(),
        include_report_period: false
      }).then((data) => {
        const options = data.data
          .filter((year: string) => +year <= moment().year())
          .sort((a: string, b: string) => +a - +b)
          .map((year: string) => ({
            value: +year,
            label: `${+year - 1}-${+year}`
          }))

        filterStore.fyOptions = options
        setReportPeriod(options[options.length - 1].value)

        SetLocalStorage("yearOptions", {
          value: options,
          expiry: moment().add(1, "day").unix()
        })
      }).catch((error: any) => { })
    }
  }, [])

  // ENTITY LEVEL PROTECTION
  useEffect(() => {
    if (userType === OrganizationTypes.ASSET_OWNER) {
      filterStore.entityIds = {
        ...filterStore.entityIds,
        assetOwnerId: {
          id: userInfo.assetOwnerId || userInfo.organization_id,
          name: userInfo.assetOwnerName || userInfo.organization_name
        }
      }

      if (filterStore.entityLevel === EntityLevel.ORGANIZATION) {
        filterStore.entityLevel = EntityLevel.ASSET_OWNER
      }

    } else if (userType === OrganizationTypes.ASSET) {
      
      filterStore.entityIds = {
        ...filterStore.entityIds,
        assetId: {
          id: userInfo.organization_id,
          name: userInfo.organization_name
        }
      }

      if (filterStore.entityLevel === EntityLevel.ORGANIZATION || filterStore.entityLevel === EntityLevel.ASSET_OWNER) {
        filterStore.entityLevel = EntityLevel.ASSET
      }
    }
  }, [filterStore.entityLevel])

  const loadEntityIdFromParams = () => {
    const assetOwnerId = searchParams.get("assetOwnerId")
    const assetId = searchParams.get("assetId")
    const assetOwnerName = searchParams.get("assetOwnerName")
    const assetName = searchParams.get("assetName")

    filterStore.entityIds = {
      assetOwnerId: assetOwnerId ? { id: assetOwnerId, name: assetOwnerName || assetOwnerId } : null,
      assetId: assetId ? { id: assetId, name: assetName || assetId } : null
    }

    filterStore.entityLevel = assetId ? EntityLevel.ASSET : assetOwnerId ? EntityLevel.ASSET_OWNER : EntityLevel.ORGANIZATION
  }

  // get region & sector options
  const loadSectorRegionOptions = async () => {
    let currentReportPeriod = reportPeriod;
    if (!currentReportPeriod) {
      currentReportPeriod = GetCurrentFY();
      // setReportPeriod(GetCurrentFY());
    }

    const cachedRegionOptions = GetLocalStorage("REGION_OPTIONS");
    if (cachedRegionOptions && cachedRegionOptions.reportPeriod === reportPeriod && moment(cachedRegionOptions.expire).isAfter(moment())) {
      filterStore.regionOptions = cachedRegionOptions.data
    } else {
      const data = await getRegionOptions(
        {
          force_refresh: false,
          include_trajectory: false,
          climate_scenario: ClimateScenario.CURRENT,
          report_period: "" + currentReportPeriod,
          region_type: "all"
        }
      )

      // Recursive function to transform the data
      const transformRegionData = (regions: any[], level: number, parentId: string): RegionNode[] => {
        if (!regions || regions.length === 0 || regions[0] === null) return [];

        return regions
          .filter(item => item.name || item.region || item.sa4 || item.sa3 || item.sa2 || item.sa1) // Filter out null entries
          .map(region => {
            const result: RegionNode = {
              id: region.region || region.sa4 || region.sa3 || region.sa2 || region.sa1 || "",
              name: region.region || region.sa4 || region.sa3 || region.sa2 || region.sa1 || "",
              level: level,
              systemLevel: region.region ? "STATE" : region.sa4 ? "SA4" : region.sa3 ? "SA3" : region.sa2 ? "SA2" : region.sa1 ? "SA1" : "",
              parentId: parentId,
              isSelected: false,
              children: []
            };

            const children = region.sa4Regions || region.sa3Regions || region.sa2Regions || region.sa1Regions || [];

            // ONLY UP TO SA4
            if (children.length > 0 && ["STATE"].includes(result.systemLevel)) {
              result.children = transformRegionData(children, level + 1, result.id);
            }

            return result;
          })
      };

      const transformedData = transformRegionData(data.data, 1, "");
      filterStore.regionOptions = transformedData;

      SetLocalStorage("REGION_OPTIONS", {
        expire: moment().add(OptionCacheExpire, "days").format("YYYY-MM-DD"),
        reportPeriod: reportPeriod,
        data: transformedData
      })
    }

    const cachedSectorOptions = GetLocalStorage("SECTOR_OPTIONS");
    if (cachedSectorOptions && cachedSectorOptions.reportPeriod === reportPeriod && moment(cachedSectorOptions.expire).isAfter(moment())) {
      filterStore.sectorOptions = cachedSectorOptions.data
    } else {
      getSectorOptions(
        {
          force_refresh: false,
          include_trajectory: false,
          climateScenario: ClimateScenario.CURRENT,
          report_period: "" + currentReportPeriod,
        }
      )
        .then((data: any) => {
          const parsedData = data.data.map((item: any) => ({
            ...item,
            isSelected: false
          }))
          filterStore.sectorOptions = parsedData
          SetLocalStorage("SECTOR_OPTIONS", {
            expire: moment().add(OptionCacheExpire, "days").format("YYYY-MM-DD"),
            reportPeriod: reportPeriod,
            data: parsedData
          })
        })
        .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
    }
  }

  const onToggleExpandedRegion = (id: string, level: number, parentId: string) => {
    setExpandedRegions(current => current.includes(`${id}_${level}_${parentId}`) ? current.filter((currentId: string) => currentId !== `${id}_${level}_${parentId}`) : [...current, `${id}_${level}_${parentId}`])
  }

  const isAllValueAssigned = (regionNode: RegionNode, isSelected: boolean) => {
    if (!regionNode.children || regionNode.children.length === 0) return regionNode.isSelected === isSelected;
    if (regionNode.children.every((item: RegionNode) => isAllValueAssigned(item, isSelected))) return true;

    return false;
  }

  const onSelectRegion = (levelObject: any, isChecked: boolean) => {
    const currentOptions = DeepCopy(filterStore.regionOptions);

    const toggledItemId = levelObject.id;
    const toggledItemParentId = levelObject.parentId;

    // recursive function to set the value
    const setValue = (object: any, value: boolean, forceSet: boolean = false, parentId: string = "") => {
      if ((object.id === toggledItemId && parentId === toggledItemParentId) || forceSet) {
        object.isSelected = value;
        forceSet = true;
      }

      if (object.children && object.children.length > 0) {
        object.children.forEach((child: any) => setValue(child, value, forceSet, object.id))
      } else {
        return;
      }
    }

    currentOptions.forEach((item: any) => setValue(item, isChecked, false, ""))

    const adaptChildrenStatus = (item: RegionNode): boolean => {
      if (!item.children || item.children.length === 0) return item.isSelected;

      item.isSelected = item.children.every((child: RegionNode) => adaptChildrenStatus(child));
      return item.isSelected;
    }

    // Check if the parent region is all empty
    currentOptions.forEach((item: RegionNode) => {
      if (!item.children || item.children.length === 0) return;
      item.isSelected = adaptChildrenStatus(item);
    })

    const newStatus = [...currentOptions]

    filterStore.regionOptions = newStatus
  }

  const isExpanded = (id: string, level: number, parentId: string) => {
    return expandedRegions.includes(`${id}_${level}_${parentId}`)
  }

  const handleClickSearchRegionSector = () => {
    if (localSearchType === SearchType.REGION) {
      const valuesList: RegionNode[] = []

      // FIND ALL SELECTED CHILDREN
      const addChildren = (item: RegionNode) => {
        if (!item.children || item.children.length === 0) {
          if (item.isSelected && item.systemLevel === "SA4") { // ONLY CHECKING SA4 LEVEL
            valuesList.push(item)
          }
          return;
        } else {
          item.children.forEach((child: RegionNode) => addChildren(child))
        }
      }

      filterStore.regionOptions.forEach((item: RegionNode) => addChildren(item))
      filterStore.searchedRegions = valuesList
    }

    else if (localSearchType === SearchType.SECTOR) {
      const valuesList: any[] = []

      for (const item of filterStore.sectorOptions) {
        if (item.isSelected) {
          valuesList.push(item)
        }
      }

      filterStore.searchedSectors = valuesList
    }

    onTriggerAfterCheckRegion && onTriggerAfterCheckRegion(localSearchType, filterStore.searchedRegions, filterStore.searchedSectors)
    filterStore.searchType = localSearchType
    setOpenQuickSearch(false)
  }

  const handleDeleteRegion = (itemToUncheck: RegionNode) => {
    const currentOptions = DeepCopy(filterStore.regionOptions);

    const idHunter = (loopItem: RegionNode, forceSet: boolean = false) => {
      if (itemToUncheck.id === loopItem.id || forceSet) {
        loopItem.isSelected = false;
        forceSet = true;
      }

      if (loopItem.children && loopItem.children.length > 0) {
        loopItem.children.forEach((child: RegionNode) => idHunter(child, forceSet))
      }
    }

    currentOptions.forEach((item: RegionNode) => idHunter(item))
    const newStatus = [...currentOptions]

    filterStore.regionOptions = newStatus
  }

  const handleDeleteSector = (itemToUncheck: any) => {
    const currentOptions = DeepCopy(filterStore.sectorOptions);

    const newStatus = currentOptions.map((item: any) => item.id === itemToUncheck.id ? { ...item, isSelected: false } : item)
    filterStore.sectorOptions = newStatus
  }

  const onSelectSector = (checkedItem: any, isChecked: boolean) => {
    const currentOptions = DeepCopy(filterStore.sectorOptions);

    currentOptions.forEach((item: any) => {
      if (item.id === checkedItem.id) {
        item.isSelected = isChecked;
      }
    })

    const newStatus = [...currentOptions]
    filterStore.sectorOptions = newStatus
  }

  return (
    <>
      <div className="App">
        <Box className="min-h-screen" sx={{ background: "linear-gradient(180.85deg, #E6EFFF 31.78%, #FFFFFF 99.27%)" }}>
          <Box className="flex p-6">
            {/* Left */}
            <Box className="w-72">
              <button onClick={() => navigate({ pathname: "/summary/dashboard", search: `?${createSearchParams(searchParams)}` })}>
                <Box className="p-4 pl-2">
                  <img src={Logo} alt="Wollemai Logo" width="90%" className="object-cover" />
                </Box>
              </button>
              <Box className="-mt-2">
                {
                  filterStore.entityLevel === EntityLevel.ASSET ? <p className='text-gray-500 text text-sm'>Asset View</p> :
                    filterStore.entityLevel === EntityLevel.ASSET_OWNER ? <p className='text-gray-500 text-sm'>Asset Owner View</p> :
                      filterStore.entityLevel === EntityLevel.ORGANIZATION ? <p className='text-gray-500 text-sm'>Organization View</p> : ""
                }
              </Box>
              <button className="w-full flex items-center mt-5 mb-3 p-2 rounded-l-xl hover:opacity-75" style={{ background: 'linear-gradient(90deg, #15F5BA 0%, rgba(21, 245, 186, 0.2) 100%)' }}
                onClick={() => setOpenQuickSearch(true)}>
                <Sort sx={{ transform: 'scaleX(-1)', fontSize: 20 }} />
                <p className="ml-2">Filters</p>
              </button>
              {
                links.map(link => (
                  <NavItem icon={link.icon} onClick={() => navigate({ pathname: link.path, search: `?${createSearchParams(searchParams)}` })} isActive={isActiveLink(link.path)}>{link.label}</NavItem>
                ))
              }
            </Box>
            {/* End Left */}
            {/* Right */}
            <Box className="flex-1 text-left rounded-2xl min-h-screen bg-[#FFFFFFA6] px-4 py-2 overflow-x-auto overflow-y-hidden">
              {/* Search */}
              <Box className="py-2 border-b mb-2 flex justify-between items-center">
                <Box className="flex items-center bg-white rounded-lg p-1 gap-2">
                  <Autocomplete
                    value={searchKey}
                    size="small"
                    // onFocus={() => searchEntityOptions("")}
                    onChange={(e, value: any) => setSelectedSearchEntity(value)}
                    onInputChange={(e, value: any) => setSearchKey(value)}
                    options={searchedEntityOptions}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} InputProps={{ ...params.InputProps, startAdornment: <Search className='text-gray-300 ml-1' /> }} />}
                  />
                  <button className='text-white bg-sky-600 py-2 px-6 rounded-lg' onClick={onClickedSearchEntity}>Search</button>
                </Box>
                <button className="flex items-center text-gray-500 hover:underline" onClick={(e) => setUserAnchor(e.currentTarget)}>
                  <Box className="rounded-full w-[30px] h-[30px] text-white bg-sky-600 relative">
                    <p className='absolute top-1/2 left-0 w-full -translate-y-1/2 text-center text-lg font-semibold'>S</p>
                  </Box>
                  <Box className="flex items-center" sx={{ marginTop: '-3px' }}>
                    <p className='ml-2 mr-1'>{userInfo?.email}</p>
                    <span style={{ marginTop: 2 }}><KeyboardArrowDown color='inherit' /></span>
                  </Box>
                </button>
              </Box>
              {/* End Search */}

              {/* Main Content */}
              {children}
            </Box>
            {/* End Right */}
          </Box>
        </Box>
        {/* Auth User Menu */}
        <Menu
          anchorEl={userAnchor}
          open={Boolean(userAnchor)}
          onClose={() => setUserAnchor(null)}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1, },
              '&:before': {
                content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => signOut()}>
            <ListItemIcon><Logout fontSize="small" /></ListItemIcon>
            Sign Out
          </MenuItem>
        </Menu>
        {/* End Auth User Menu */}
        {/* Quick Search */}
        <Drawer anchor="left" open={openQuickSearch} onClose={() => setOpenQuickSearch(false)} sx={{
          width: '70%', '& .MuiDrawer-paper': { width: '70%' }
        }}>
          <Box className="p-4">
            <Box className="flex items-center justify-between">
              <p className='font-bold'>Quick Search</p>
              <IconButton onClick={() => setOpenQuickSearch(false)}>
                <Close />
              </IconButton>
            </Box>
            <Box className="mt-2 flex items-center text-gray-500 border-b py-2">
              <p className='mr-6 text-gray-600 -mt-1'>Search based on</p>
              <RadioGroup row value={localSearchType} onChange={(e: any) => setLocalSearchType(e.target.value)}>
                <FormControlLabel value={SearchType.REGION} control={<Radio />} label="Region" />
                <FormControlLabel value={SearchType.SECTOR} control={<Radio />} label="Sector" />
              </RadioGroup>
            </Box>
            <Box className="grid grid-cols-6 border-b">
              {/* Left */}
              <Box className="col-span-3 min-h-[80vh] max-h-[80vh] modalScrollBar overflow-auto text-gray-500">
                {
                  localSearchType === SearchType.REGION
                    ? filterStore.regionOptions.map((level1: any) => (<>
                      <Box className="flex items-center">
                        <Checkbox checked={level1.isSelected} onChange={(e) => onSelectRegion(level1, e.target.checked)} />
                        <button className='flex items-center text-left' onClick={() => { onToggleExpandedRegion(level1.id, 1, level1.parentId) }}>
                          {
                            level1.children.length > 0 && <KeyboardArrowDown sx={{ transform: isExpanded(level1.id, 1, level1.parentId) ? 'rotate(0deg)' : 'rotate(270deg)', transition: 'ease' }} />
                          }
                          <LocationOn />
                          <p className='ml-1 text-sm'>{level1.name}</p>
                        </button>
                      </Box>
                      {level1.children.length > 0 && <Collapse in={isExpanded(level1.id, 1, level1.parentId)} timeout={200} unmountOnExit>
                        {level1.children.map((level2: any) => (<Box className="ml-9">
                          <Box className="flex items-center">
                            <Checkbox checked={level2.isSelected} onChange={(e) => onSelectRegion(level2, e.target.checked)} />
                            <button className='flex items-center text-left' onClick={() => onToggleExpandedRegion(level2.id, 2, level2.parentId)}>
                              {
                                level2.children.length > 0 && <KeyboardArrowDown sx={{ transform: isExpanded(level2.id, 2, level2.parentId) ? 'rotate(0deg)' : 'rotate(270deg)', transition: 'ease' }} />
                              }
                              <LocationOn />
                              <p className='ml-1 text-sm'>{level2.name}</p>
                            </button>
                          </Box>

                          {level2.children.length > 0 && <Collapse in={isExpanded(level2.id, 2, level2.parentId)} timeout={200} unmountOnExit>
                            {level2.children.map((level3: any) => (<Box className="ml-9">
                              <Box className="flex items-center">
                                <Checkbox checked={level3.isSelected} onChange={(e) => onSelectRegion(level3, e.target.checked)} />
                                <button className='flex items-center text-left' onClick={() => { onToggleExpandedRegion(level3.id, 3, level3.parentId) }}>
                                  {
                                    level3.children.length > 0 && <KeyboardArrowDown sx={{ transform: isExpanded(level3.id, 3, level3.parentId) ? 'rotate(0deg)' : 'rotate(270deg)', transition: 'ease' }} />
                                  }
                                  <LocationOn />
                                  <p className='ml-1 text-sm'>{level3.name}</p>
                                </button>
                              </Box>

                              {level3.children.length > 0 && <Collapse in={isExpanded(level3.id, 3, level3.parentId)} timeout={200} unmountOnExit>
                                {level3.children.map((level4: any) => (<Box className="ml-9">
                                  <Box className="flex items-center">
                                    <Checkbox checked={level4.isSelected} onChange={(e) => onSelectRegion(level4, e.target.checked)} />
                                    <button className='flex items-center text-left' onClick={() => { onToggleExpandedRegion(level4.id, 4, level4.parentId) }}>
                                      {
                                        level4.children.length > 0 && <KeyboardArrowDown sx={{ transform: isExpanded(level4.id, 4, level4.parentId) ? 'rotate(0deg)' : 'rotate(270deg)', transition: 'ease' }} />
                                      }
                                      <LocationOn />
                                      <p className='ml-1 text-sm'>{level4.name}</p>
                                    </button>
                                  </Box>

                                  {level4.children.length > 0 && <Collapse in={isExpanded(level4.id, 4, level4.parentId)} timeout={200} unmountOnExit>
                                    {level4.children.map((level5: any, i: number) => (<Box className="ml-11">
                                      <Box className={`flex items-center ${i === 0 ? "mt-2" : ""} mb-4`}>
                                        <button className='flex items-center text-left'>
                                          <LocationOn />
                                          <p className='ml-1 text-sm'>{level5.name}</p>
                                        </button>
                                      </Box>
                                    </Box>))}
                                  </Collapse>}
                                </Box>))}
                              </Collapse>}

                            </Box>))}
                          </Collapse>}
                        </Box>))}
                      </Collapse>}
                    </>))
                    : (<>
                      {
                        filterStore.sectorOptions.map((item: any) => (
                          <Box className="flex items-center">
                            <FormControlLabel control={<Checkbox checked={item.isSelected}
                              onChange={(e) => onSelectSector(item, e.target.checked)} />} label={
                                <Box className="flex gap-1 items-center">
                                  <img src={SectorIcon} width={24} className='mr-1' />
                                  <p className=''>{FormatKeyToLabel(item.name)}</p>
                                </Box>
                              } />
                          </Box>
                        ))
                      }
                    </>)
                }
              </Box>
              {/* End Left */}
              {/* Center */}
              <Box className="col-span-2 border-l border-r p-2 min-h-[80vh] max-h-[80vh] modalScrollBar overflow-auto">
                <Box className="flex items-center text-sm">
                  {
                    localSearchType === SearchType.REGION
                      ? <p>Selected Region</p>
                      : <p>Selected Sector</p>
                  }
                  <button className='text-sky-500 ml-4 hover:underline' onClick={() => handleResetSelectedRegion()}>Reset</button>
                </Box>
                <Box>
                  {
                    localSearchType === SearchType.REGION
                      ? filterStore.regionOptions.map((item: RegionNode, item_index: number) => (
                        <SelectedRegionItem key={`selected_region_${item.id}_${item.parentId}_${item_index}`} item={item} handleDelete={handleDeleteRegion} />
                      ))
                      : filterStore.sectorOptions
                        .filter((item: any) => item.isSelected)
                        .map((item: any, item_index: number) => (
                          <SelectedSectorItem key={`selected_sector_${item.id}_${item_index}`} item={item} handleDelete={handleDeleteSector} />
                        ))
                  }
                </Box>
              </Box>
              {/* End Center */}
              {/* Right */}
              <Box className="text-sm p-2">
                <p>Associated {localSearchType === SearchType.REGION ? "Sector" : "Region"}</p>
                <p className='text-base font-bold mt-4'>--</p>
              </Box>
              {/* End Right */}
            </Box>
            <Box className="flex justify-end mt-2">
              <button className='bg-sky-500 text-white rounded-lg py-2 px-10 border-2 border-sky-500 hover:border-black hover:text-black hover:bg-transparent transition-all' onClick={() => handleClickSearchRegionSector()}>Search</button>
            </Box>
          </Box>
        </Drawer>
        {/* End Quick Search */}
      </div>
    </>
  );
};

const NavItem = ({ icon, children, isActive = false, onClick }: { icon: any, children?: any, isActive: boolean, onClick: () => any; }) => {
  return (
    <button className={`w-full flex items-center mt-1 pl-8 p-2 rounded-l-xl hover:opacity-75 ${isActive ? "bg-[#FFFFFFA6]" : ""} transition-all duration-300`}
      onClick={onClick}>
      <span className={`${isActive ? 'text-sky-600' : 'text-sky-300'} transition-all`}>{icon}</span>
      <p className={`${isActive ? 'text-gray-600 font-semibold' : 'text-gray-500'} transition-all ml-2`}>{children}</p>
    </button>
  );
};

const SelectedSectorItem = ({ item, handleDelete }: { item: any, handleDelete: (item: any) => any }) => {
  return (
    <Box className="pl-2 bg-[#E6EFFF] rounded-md text-gray-500 mt-2 flex justify-between item-start">
      <Box className='flex items-center'>
        <p>{FormatKeyToLabel(item.name)}</p>
      </Box>
      <IconButton onClick={() => handleDelete(item)}>
        <Close />
      </IconButton>
    </Box>
  )
}

const SelectedRegionItem = ({ item, parents = [], handleDelete }: { item: RegionNode, parents?: RegionNode[], handleDelete: (item: RegionNode) => any }) => {
  const isAllSelected = (regionNode: RegionNode) => {
    if (!regionNode.children || regionNode.children.length === 0) return regionNode.isSelected;
    if (regionNode.children.every((item: RegionNode) => isAllSelected(item))) return true;

    return false;
  }

  return (
    <>
      {
        isAllSelected(item)
          ? <Box className="p-2 bg-[#E6EFFF] rounded-md text-gray-500 text-sm mt-2 flex justify-between item-start" onClick={() => handleDelete(item)}>
            <Box className='flex items-center'>
              {parents?.map((parent: RegionNode, parentIndex: number) => (
                <Box key={`selected_region_${parent.id}_${parentIndex}`} className="flex items-center" style={{ marginLeft: (Math.max(parentIndex - 1, 0)) * 10 }}>
                  {parent.level !== 1 && <SubdirectoryArrowRight fontSize='small' />}
                  <p>{parent.name}</p>
                </Box>
              ))}
              <Box className="flex items-center " style={{ marginLeft: (Math.max(parents.length - 1, 0)) * 10 }}>
                {item.level !== 1 && <SubdirectoryArrowRight fontSize='small' />}
                <p className='text-black text-base ml-1'>{item.name}</p>
              </Box>
            </Box>

            <Box>
              <IconButton onClick={() => handleDelete(item)}>
                <Close />
              </IconButton>
            </Box>

          </Box>
          : item.children.map((child: RegionNode) => (
            <SelectedRegionItem key={`selected_region_${child.id}_${child.parentId}`} item={child} parents={[...parents, item]} handleDelete={handleDelete} />
          ))
      }
    </>
  )
}

export default observer(Layout);


// <div style={{ display: 'flex' }}>
// <CssBaseline />

// {/* NavBar */}
// <NavBar toggleDrawer={toggleDrawer} /> {/* Use NavBar component */}

// {/* SideBar */}
// <SideBar isDrawerOpen={isDrawerOpen} drawerWidth={drawerWidth} /> {/* Use SideBar component */}

// {/* Main Content */}
// <main style={{ flexGrow: 1, padding: '24px', marginLeft: isDrawerOpen ? drawerWidth : 0, transition: 'margin 0.3s' }}>
//   <Toolbar />
//   <Outlet />
// </main>
// </div>