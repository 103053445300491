import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import Layout from '../layouts/Layout';
import FilterSection, { FilterLevel, FilterLevelOne, FilterLevelThree, FilterLevelTwo } from '../components/summary/filter-section';
import { observer } from 'mobx-react-lite';
import theme from '../styles/theme';
import VmCheckBox from '../components/VmCheckBox';
import { ArrowForwardIos, ArrowUpward, Close } from '@mui/icons-material';
import { GenerateCompareToYearOptions, GenerateFYOptions } from '../utils/general';
import moment from 'moment';
import VmDropdown from '../components/VmDropdown';
import VmModal from '../components/VmModal';
import SectorCompareBarChart from '../components/charts/sector-compare-bar-chart';
import RegionDataTable from '../components/charts/region-data-table';
import SingleBarChart from '../components/charts/single-bar-chart';
import ScopeCompareBarChart from '../components/charts/scope-compare-bar-chart';
import LineChartWithMarkings from '../components/charts/line-chart-with-markings';
import CriteriaHeader from '../layouts/criteria-header';
import SummaryAEEmissionSummaryEmissionSummary from '../components/summary-page-sections/ae/ae_emissions_emission_summary';
import SummeryAESectorSection from '../components/summary-page-sections/ae/ae_emissions_sector';
// import SummeryAENonBioEmissionIntensityByScope from '../components/summary-page-sections/ae/ae_intensity_non_bio_emission_backup';
import SummeryAERegionSection from '../components/summary-page-sections/ae/ae_emissions_region';
import SummaryLURemovalSummarySection from '../components/summary-page-sections/lu_removals/lu_summary';
// import SummaryLULandUseTypeSection from '../components/summary-page-sections/lu_removals/lu_landusetype-backup';
// import SummaryRemovalsSummarySection from '../components/summary-page-sections/lu_removals/removal_summary_backup';
import SummaryCarbonStockTypeSection from '../components/summary-page-sections/lu_removals/removal_carbon_stock_type';

import { useSearchParams } from 'react-router-dom';
import SummaryRemovalsMapSection from '../components/summary-page-sections/lu_removals/removal_map';
import SummeryFEScopeSection from '../components/summary-page-sections/fe/fe_emission_scope_section';
import SummeryFESectorSection from '../components/summary-page-sections/fe/fe_emission_sector_section';
import SummaryFERegionSection from '../components/summary-page-sections/fe/fe_emission_region_section';
import SummaryPhysicalEEIPEIWACI from '../components/summary-page-sections/fe/fe_physical_eei_section';
import SummaryPRAcuteDroughtSection from '../components/summary-page-sections/pr/pr_acute_drought';
import SummaryGHGTypesSection from '../components/summary-page-sections/ae/ae_emissions_ghg_types';
import SummeryAEIntensityIntensityHaProduction from '../components/summary-page-sections/ae/ae_intensity_intensity_ha_production';
import { EntityLevel, SearchType } from '../utils/options';
import { FilterContext } from '../context/filter-bar-context';
import { FilterStoreContext } from '../context/filter-store-context';
import ErrorBoundary from '../components/error_boundary';

// const FinancialPeriods = GenerateFYOptions();
// const CompareToYearOptions = GenerateCompareToYearOptions();

const SummaryPage: React.FC = () => {
  const filterStore = useContext(FilterStoreContext);
  const [filterLevelOneId, setFilterLevelOneId] = useState<number>(FilterLevelOne.AE);
  const [filterLevelTwoId, setFilterLevelTwoId] = useState<number>(0);
  const [filterLevelThreeId, setFilterLevelThreeId] = useState<number>(0);

  const [openClimateChangeModal, setOpenClimateChangeModal] = useState<boolean>(false);

  const [localEntityLevelFilters, setLocalEntityLevelFilters] = useState<any>({})
  const [localSearchType, setLocalSearchType] = useState<any>(null);
  const [localSelectedRegions, setLocalSelectedRegions] = useState<any[]>([]);
  const [localSelectedSectors, setLocalSelectedSectors] = useState<any[]>([]);

  const handleFilterChange = ({ levelOneId, levelTwoId, levelThreeId }: { levelOneId: number, levelTwoId: number, levelThreeId: number }) => {
    setFilterLevelOneId(levelOneId);
    setFilterLevelTwoId(levelTwoId);
    setFilterLevelThreeId(levelThreeId);
  };

  if (filterStore.entityLevel !== EntityLevel.ORGANIZATION && filterLevelOneId === FilterLevelOne.FINANCED) {
    setFilterLevelOneId(FilterLevelOne.AE);
  }

  const handleCheckRegion = (searchType: SearchType, selectedRegions: any[], selectedSectors: any[]) => {
    setLocalSearchType(searchType);
    setLocalSelectedRegions(selectedRegions);
    setLocalSelectedSectors(selectedSectors);
  }

  return (
    <Layout onTriggerAfterSearch={setLocalEntityLevelFilters} onTriggerAfterCheckRegion={handleCheckRegion} >

      <CriteriaHeader
        breadCrumb={
          filterLevelOneId === FilterLevelOne.AE ? "Summary / Absolute Emissions" :
            filterLevelOneId === FilterLevelOne.FINANCED ? "Summary / Financial Emissions" :
              filterLevelOneId === FilterLevelOne.LAND_USE_AND_REMOVALS ? "Summary / Land Use & Carbon Removals" :
                filterLevelOneId === FilterLevelOne.PHYSICAL_RISK ? "Summary / Physical Risk" :
                  "Summary"
        }
        entityLevelToggleCallback={setLocalEntityLevelFilters}
      />

      <FilterSection callback={handleFilterChange} />

      <Box className="mt-4" key={`${filterLevelOneId}_${filterLevelTwoId}_${filterLevelThreeId}_${JSON.stringify(localEntityLevelFilters)}_${localSearchType}_${JSON.stringify(localSelectedRegions)}_${JSON.stringify(localSelectedSectors)}`}>
        <ErrorBoundary>
        {/* AE SECTION */}
        {
          filterLevelOneId === FilterLevelOne.AE && <>
            {/* AE => EMISSIONS */}
            {
              filterLevelTwoId === FilterLevelTwo.AE_EMISSIONS && <>
                {
                  filterLevelThreeId === FilterLevelThree.AE_EMISSION_EMISSION_SUMMARY && <>
                    <SummaryAEEmissionSummaryEmissionSummary />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.AE_EMISSION_EMISSION_GHG_TYPES && <>
                    <SummaryGHGTypesSection />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.AE_EMISSION_SECTOR && <>
                    <SummeryAESectorSection />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.AE_EMISSION_REGION && <>
                    <SummeryAERegionSection />
                  </>
                }
              </>
            }

            {/* AE => INTENSITY */}
            {
              filterLevelTwoId === FilterLevelTwo.AE_PHYSICAL && <>
                {
                  (
                    filterLevelThreeId === FilterLevelThree.AE_EMISSION_INTENSITY_INTENSITY_HA ||
                    filterLevelThreeId === FilterLevelThree.AE_EMISSION_INTENSITY_INTENSITY_PRODUCTION
                  ) && <>
                    <SummeryAEIntensityIntensityHaProduction levelThreeId={filterLevelThreeId} />
                  </>
                }
              </>
            }
          </>
        }

        {/* FE SECTION */}
        {
          filterLevelOneId === FilterLevelOne.FINANCED && <>

            {/* FINANCED => EMISSIONS */}
            {
              filterLevelTwoId === FilterLevelTwo.FINANCED_EMISSIONS && <>
                {
                  filterLevelThreeId === FilterLevelThree.FINANCED_EMISSION_SCOPE && <>
                    <SummeryFEScopeSection />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.FINANCED_EMISSION_SECTOR && <>
                    <SummeryFESectorSection />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.FINANCED_EMISSION_REGION && <>
                    <SummaryFERegionSection />
                  </>
                }
              </>
            }

            {/* FINANCED => PHYSICAL */}
            {
              filterLevelTwoId === FilterLevelTwo.FINANCED_PHYSICAL && <>
                <SummaryPhysicalEEIPEIWACI levelThree={filterLevelThreeId} />
              </>
            }
          </>
        }

        {/* LAND USE AND REMOVALS SECTION */}
        {
          filterLevelOneId === FilterLevelOne.LAND_USE_AND_REMOVALS && <>

            {/* LAND USE AND REMOVALS => LAND USE */}
            {
              filterLevelTwoId === FilterLevelTwo.LAND_USE_LAND_USE && <>
                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_SUMMARY && <>
                    <SummaryLURemovalSummarySection levelThreeId={filterLevelThreeId} />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_MAP && <>
                    <SummaryRemovalsMapSection levelThreeId={filterLevelThreeId} />
                  </>
                }

                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_LAND_USE_TYPE && <>
                    <SummaryCarbonStockTypeSection levelThreeId={filterLevelThreeId} />
                  </>
                }
              </>
            }

            {/* LAND USE AND REMOVALS => REMOVALS */}
            {
              filterLevelTwoId === FilterLevelTwo.LAND_USE_LAND_REMOVALS && <>
                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_REMOVALS_SUMMARY && <>
                    <SummaryLURemovalSummarySection levelThreeId={filterLevelThreeId} />
                  </>
                }
                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_REMOVALS_MAP && <>
                    <SummaryRemovalsMapSection levelThreeId={filterLevelThreeId} />
                  </>
                }
                {
                  filterLevelThreeId === FilterLevelThree.LAND_USE_REMOVALS_CARBON_STOCK_TYPE && <>
                    <SummaryCarbonStockTypeSection levelThreeId={filterLevelThreeId} />
                  </>
                }
              </>
            }
          </>
        }

        {/* PHYSICAL RISK SECTION */}
        {
          filterLevelOneId === FilterLevelOne.PHYSICAL_RISK && <>
            <SummaryPRAcuteDroughtSection levelTwo={filterLevelTwoId} levelThree={filterLevelThreeId} />
          </>
        }
        </ErrorBoundary>
      </Box>


      <VmModal open={openClimateChangeModal} onClose={() => setOpenClimateChangeModal(false)} >
        <Box className="flex flex-col gap-4">
          <Box className="flex justify-between items-center">
            <Typography variant="h5">Climate Change - Scope</Typography>
            <IconButton onClick={() => setOpenClimateChangeModal(false)}>
              <Close />
            </IconButton>
          </Box>

          {/* Switch between disorderly and hothouse */}
          <Box className="flex gap-2 items-center">
            <Button variant="outlined" sx={{ borderRadius: '4px' }}>1.5 (RCP2.6 /NGFS Disorderly-Delayed)</Button>
            <Button variant="outlined" sx={{ borderRadius: '4px', color: "black", borderColor: "black", opacity: 0.3 }} >2.5 (RCP4.5/NGFS Hothouse-Current)</Button>
          </Box>

          <p>There will be a chart almost identical to the one outside.</p>
        </Box>
      </VmModal>
    </Layout >
  );
};

export default observer(SummaryPage);
