import moment from 'moment';
import { useContext } from 'react';
import { FilterContext } from '../context/filter-bar-context';
import { ClimateScenario, EntityLevel } from './options';
import { useNavigate } from 'react-router-dom';


// export const GenerateFYOptions = (startYear: number, endYear: number) => {
//     const options = [];
//     for (let i = startYear; i <= endYear; i++) {
//         options.push({ value: i, label: i });
//     }
//     return options;
// };

export const GenerateFYOptions = () => {
  const financialYearOptions = [];
  const currentYear = moment().year();
  const currentMonth = moment().month();
  for (let i = currentMonth + 1 < 7 ? 0 : 1; i <= 3; i++) {
    const startYear = currentYear - i;
    financialYearOptions.push({
      value: startYear,
      label: `${startYear}-${startYear + 1}`
    });
  }
  return financialYearOptions;
};


export const GenerateCompareToYearOptions = (yearAmounts?: number, isFuture?: boolean, allowNoCompareToYears?: boolean) => {
  const options = [];

  if (allowNoCompareToYears) {
    options.push({
      value: 0,
      label: `No comparison`
    });
  }

  if (yearAmounts === 0) {
    return options
  }

  for (let i = 1; i <= (yearAmounts || 3); i++) {
    options.push({
      value: i,
      label: `${isFuture ? "Next" : "Last"} ${i} years`
    });
  }
  return options
}

export const GetCurrentFY = () => {
  const currentYear = moment().year();
  const currentMonth = moment().month();

  return currentMonth + 1 < 7 ? currentYear - 1 : currentYear;
}

export const FormatFYChartTitle = (fy: number, reportPeriod: number = GetCurrentFY()) => {
  if (+fy === +reportPeriod) return `${fy} (Current)`
  else if (+fy < +reportPeriod) return `${fy} (History)`
  else return `${fy} (Future)`
}

export const isUpperCase = (str: string) => {
  if (typeof str !== "string") return false;
  if (!str) return false;
  return str === str.toUpperCase();
}

export const FormatKeyToLabel = (key: string): string => {
  if (typeof key !== "string") return "";

  // Handle special cases first
  if (!key) return "";

  if (isUpperCase(key)) return key;

  if (key.toLowerCase().startsWith("farm_")) {
    return key.toLowerCase().replace("farm_", "Farm ")
  }

  // Split by underscores first, then handle camelCase within each part
  const words = key
    .split('_')
    .map(part =>
      part
        .split(/(?=[A-Z0-9])|(?<=[a-zA-Z])(?=[0-9])|(?<=[a-zA-Z])(?=[0-9])+/g)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
    )
    .join(' ');

  return words;
}

export const IsEmptyStr = (str: any) => {
  return !str || str === "";
}

export const IsValidEmailAddress = (address: any) => {
  return address && /\S+@\S+\.\S+/.test(address);
}

export const IsZero = (str: any) => {
  return !str || +str === 0;
}

export const IsNumeric = (str: any) => {
  return str && /^\d+(\.\d+)?$/.test(str);
}

// Time
export const CNDateTimeFormat = "YYYY-MM-DD HH:mm";
export const CNDateFormat = "YYYY-MM-DD";
export const AUDateTimeFormat = "DD/MM/YYYY hh:mm A";
export const AUTimeFormat = "hh:mm A";
export const AUDDateFormat = "DD/MM/YYYY";
export const AUDTimeFormat = "hh:mm A";
export const AUStrDateTimeFormat = "DD MMM YYYY HH:mm A";
export const AUStrFullDateTimeFormat = "ddd, DD MMM YYYY HH:mm A";
export const AUStrDateFormat = "DD MMM YYYY";
export const AUStrDateTimeNonYearFormat = "DD MMM HH:mm A";

export enum Priority {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
}

export const GetViewingEntityLevel = (entityIds: any) => {
  if (entityIds.assetId) return EntityLevel.ASSET;
  if (entityIds.assetOwnerId) return EntityLevel.ASSET_OWNER;
  return EntityLevel.ORGANIZATION;
}

export const DeepCopy = (oldObject: any) => {
  if (oldObject === null || oldObject === undefined) return null;

  return JSON.parse(JSON.stringify(oldObject));
};

export const APIErrorHandler = (error: any) => {
  console.log(error)
  if (error.response) {
    if (error.response.status === 401) {
      window.location.href = "/login"
      return "Token expired, please login again."
    } else if (error.response.status === 404) {
      return "URL does not exist"
    } else {
      return error.response.data.message
    }
  } else {
    return error.message
  }
}

export const TransformRegionOptionsParam = (options: any[]) => {
  if (options.length === 0) return ""
  const transformedOptions = options.map((item: any) => ({
    "SA4": item.id,
    "state": item.parentId,
  }))

  return transformedOptions
}

export const TransformSectorOptionsParam = (options: any[]) => {
  if (options.length === 0) return ""
  const transformedOptions = options.map((item: any) => ({
    "emission_system_id": item.id,
    "anzsic_code": item.ansic_code,  // TODO: IS THIS STILL THE CORRECT FIELD?
  }))

  return transformedOptions
}

export const GenerateExportUrl = (
  level1: "ae" | "fe" | "lu" | "pr",
  level2: string,
  level3: string,
  additionalParams: string = '') => {
  const url = `/api/v2/portfolio/asset/summary/${level1}/${level2}/${level3}${additionalParams}/export`
  return `${url}`
}

export const ParseChangeValues = (change: number) => {
  if (!change) return 0;
  if (isNaN(change)) return 0;

  change *= 100;

  if (Math.abs(change) > 1) return +change.toFixed()
  else if (Math.abs(change) > 0.5) return +change.toFixed(1)
  else return 0
}

export const GetScenarioName = (scenario: ClimateScenario) => {
  switch (scenario) {
    case ClimateScenario.CURRENT: return "Current"
    case ClimateScenario.DISORDERLY: return "Disorderly"
    default: return "Unknown"
  }
}

export const GetMinYAxisValue = (chartData: any[]) => {
  let dynamicMinYAxisValue: number = 0;
  for (const data of chartData) {
    if (data.data.length > 0) {
      if (dynamicMinYAxisValue === 0) {
        dynamicMinYAxisValue = Math.min(...data.data.map((value: any) => value ? value.value : 0))
      } else {
        dynamicMinYAxisValue = Math.min(dynamicMinYAxisValue, Math.min(...data.data.map((value: any) => value ? value.value : 0)))
      }
    }
  }

  return +dynamicMinYAxisValue.toFixed()
}


export const DownloadCSV = (thead: any[], tbody: any[], entityList: any[], fileName: string) => {
  const csvRows = [
    thead,
    ...entityList.map(item => tbody.map((columnInfo: any) => {
      let fields = columnInfo.fields
      if (fields.fields) fields = fields.fields;
      let key = fields[0]

      return item[key]
    }))
  ];

  // Convert to CSV string
  const csvString = csvRows
    .map(row =>
      row.map(cell =>
        // Handle cells that might contain commas or quotes
        typeof cell === 'string' ? `"${cell.replace(/"/g, '""')}"` : cell
      ).join(',')
    )
    .join('\n');

  // Create blob and download
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
