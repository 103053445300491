import React, { useContext, useEffect, useRef, useState } from 'react'
import RegionDataTable from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData } from '../../../api/explorerService'
import { ClimateScenario, EntityLevel, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import moment from 'moment'
import { APIErrorHandler, FormatFYChartTitle, FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions, GetMinYAxisValue, GetScenarioName, ParseChangeValues } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'
import VmModal from '../../VmModal'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { Close, KeyboardArrowDown, List, Timeline } from '@mui/icons-material'
import SimpleLineChart from '../../charts/simple-line-chart'
import SimpleBarChart from '../../charts/simple-bar-chart'
import { defaultDataKeyColours, lerpColor, RandomRGB } from '../../../utils/colour'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import { useSnackbar } from 'notistack'
import { FilterStoreContext } from '../../../context/filter-store-context'
import BannerText from '../../BannerText'
import MultiYAxisLineChart from '../../charts/multi-yaxis-line-chart'

let statDataKeys = [
  "CarbonRemovals",
  "GrossEmission",
  "ProductionIntensity",
  "PhysicalIntensity",
  "NetEmissions",
  "NetIntensity"
]

const PAGE_SIZE = 5

const SummaryAEEmissionSummaryEmissionSummary = observer(({ }: {}) => {
  const { reportPeriod, compareToYears, setAllowedCompareToYears } = useContext(FilterContext)
  const filterStore = useContext(FilterStoreContext);
  const { enqueueSnackbar } = useSnackbar();

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])

  const [barChartData, setBarChartData] = useState<any>([])
  const [fyOptions, setFyOptions] = useState<number[]>([])

  // MODE TOGGLE
  const [modeToggleSelected, setModeToggleSelected] = useState<string>("Table")

  // CLIMATE CHANGE MODAL
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [selectedClimateScenario, setSelectedClimateScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)
  const [climateChangeModalFyOptions, setClimateChangeModalFyOptions] = useState<number[]>([])
  const [climateChangeModalLineChartData, setClimateChangeModalLineChartData] = useState<any>([])
  const [climateChangeModalLoading, setClimateChangeModalLoading] = useState<boolean>(false)

  const dataKeyColours = useRef<{ [key: string]: string }>(defaultDataKeyColours)

  // LOADING STATES
  const [loading, setLoading] = useState<boolean>(false)

  // const [dataKeys, setDataKeys] = useState<{ key: string, title: string, unit?: string, isStatRow?: boolean, handleLoadMore?: () => void }[]>([])
  const [farmNames, setFarmNames] = useState<string[]>([])

  const refreshData = (fy: number = reportPeriod) => {
    setLoading(true)
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "emissions", "emission_summary")
      .then((data) => {
        let rawData = data.data
        if ("data" in rawData) rawData = rawData.data

        let tableData: any[] = []

        tableData = rawData.map((data: any) => {
          let newData: any = {
            FY: data.FY,
            GrossEmission: {
              ...data["GrossEmission"],
              children: [
                ...Object.keys(data)
                  .filter((key: string) => !["FY"].includes(key) && !statDataKeys.includes(key))
                  .map((key: string) => ({
                    [key]: {
                      ...data[key],
                      children: {
                        ...data[key],
                      }
                    }
                  }))
              ]
            },
            CarbonRemovals: data["CarbonRemovals"],
            NetEmissions: data["NetEmissions"],
            ProductionIntensity: data["NetIntensity"],
            PhysicalIntensity: data["PhysicalIntensity"],
          }

          delete newData["NetIntensity"];

          return newData
        })

        rawData = rawData.map((data: any) => {
          let newData: any = {
            ...data,
            FY: data.FY,
            GrossEmission: { ...data["GrossEmission"], },
            CarbonRemovals: data["CarbonRemovals"],
            NetEmissions: data["NetEmissions"],
            ProductionIntensity: data["NetIntensity"],
            PhysicalIntensity: data["PhysicalIntensity"],
          }

          delete newData["NetIntensity"];

          return newData
        })

        setChartData(tableData)

        let tempBarChartData: any[] = []

        if (rawData.length > 0) {
          let farmNames = Object
            .keys(rawData[0])
            .filter((key: string) => !["FY"].includes(key) && !statDataKeys.includes(key))

          // Assets for bar chart
          if (rawData && rawData[0]) {
            farmNames
              .forEach((key: string, keyIndex: number) => {
                tempBarChartData.push({
                  name: FormatKeyToLabel(key),
                  type: 'bar',
                  stack: 'stack1',
                  barWidth: 80,
                  data: rawData.map((data: any) => ({
                    ...data[key],
                    FY: data.FY,
                  })),
                  itemStyle: {
                    color: lerpColor("#1d62f4", "#002c8c", keyIndex / (farmNames.length - 1))
                  },
                  emphasis: {
                    focus: "series"
                  }
                })
              })
          }

          tempBarChartData.push({
            name: "Carbon Removals",
            type: 'bar',
            stack: 'stack1',
            data: rawData.map((data: any) => ({
              value: -1 * data["CarbonRemovals"].value,
              FY: data.FY
            })),
            itemStyle: {
              color: "white",
              borderColor: "#0061ff",
              borderWidth: 1,
              borderType: "dashed"
            },
            emphasis: {
              focus: "series"
            }
          })

          tempBarChartData.push({
            name: "Net Emissions",
            type: 'line',
            data: rawData.map((data: any) => ({
              value: data["NetEmissions"].value,
              FY: data.FY
            })),
            symbol: "rect",
            symbolSize: [80, 5],
            lineStyle: {
              width: 1,
              color: "#0061ff"
            },
            itemStyle: {
              color: "#15F5BA"
            },
            emphasis: {
              focus: "series"
            }
          })

          setFyOptions(rawData.map((data: any) => data.FY))

          setBarChartData(tempBarChartData)

          const firstFY = rawData[0].FY
          setAllowedCompareToYears((+fy) - (+firstFY))
        }
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setLoading(false))
  }

  const handleExportData = () => {
    exportFile(
      GenerateExportUrl("ae", "emissions", "emission_summary"),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Absolute Emission - Emissions Summary.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
  }

  const handleOpenStressTestModal = (fy: number = reportPeriod) => {
    setShowClimateChangeModal(true)
    setClimateChangeModalLoading(true)

    let climateChangeChartData: any[] = [];
    let climateChangeFYOptions: any[] = [];

    Promise.all(
      [ClimateScenario.CURRENT, ClimateScenario.DISORDERLY].map((climateScenario: ClimateScenario) => {
        return getOrganizationAEData({
          force_refresh: false,
          include_trajectory: true,
          climateScenario: climateScenario,
          reportPeriod: "" + fy,
          entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
        }, filterStore.entityLevel, "emissions", "emission_summary")
          .then((data) => {
            let rawData = data.data
            if ("data" in rawData) rawData = rawData.data

            rawData = rawData.map((data: any) => {
              let newData = {
                ...data,
                "ProductionIntensity": data["NetIntensity"],
              }
              delete data["NetIntensity"]

              return { ...newData }
            })

            if (climateChangeFYOptions.length === 0) {
              climateChangeFYOptions = rawData.map((data: any) => data.FY)
            };

            ["GrossEmission", "ProductionIntensity"].forEach((key: string) => {
              if (rawData) {
                climateChangeChartData.push({
                  name: `${FormatKeyToLabel(key)} - ${GetScenarioName(climateScenario)}`,
                  type: 'line',
                  data: rawData.map((data: any) => ({
                    ...data[key],
                    fy: data.FY,
                    climateScenario: climateScenario,
                  })),
                  climateScenario: climateScenario,
                  symbol: "roundRect",
                  symbolSize: 16,
                  itemStyle: {
                    color: dataKeyColours.current[key] || RandomRGB()
                  },
                  yAxisIndex: key.toLowerCase().includes("intensity") ? 1 : 0
                })
              }
            })
          })
      })
    )
      .then(() => {
        climateChangeChartData.sort((a: any, b: any) => a.name.localeCompare(b.name))

        setClimateChangeModalFyOptions(climateChangeFYOptions)
        setClimateChangeModalLineChartData(climateChangeChartData)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), { variant: "error" }))
      .finally(() => setClimateChangeModalLoading(false))
  }

  // const handleLoadMoreDataKeys = () => {
  //   // setDataKeys((currentKeys: any[]) => [
  //   //   ...currentKeys,
  //   //   ...farmNames
  //   //     .slice(currentKeys.length, currentKeys.length + PAGE_SIZE)
  //   //     .map((farmName: string) => ({
  //   //       key: farmName,
  //   //       unit: "Asset",
  //   //       title: farmName,
  //   //       isStatRow: false
  //   //     }))
  //   // ])
  // }

  useEffect(() => {
    refreshData()
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={() => { handleOpenStressTestModal() }}
        changeReportPeriodCallBack={(value: number) => refreshData(value)}
        modeToggleButtonOptions={[{ label: "Table", icon: <List /> }, { label: "Series", icon: <Timeline /> }]}
        modeToggleSelected={modeToggleSelected}
        onModeToggleButtonClick={(value: string) => setModeToggleSelected(value)}
        defaultBenchmark={false}
        defaultTarget={false}
        showExport={filterStore.entityLevel === EntityLevel.ASSET}
        onClickExport={handleExportData}
      />
      {
        loading
          ? <BannerText text={"Loading..."} />
          : <>
            {
              modeToggleSelected === "Table"
                ? <>
                  <RegionDataTable
                    tableData={chartData.filter((data: any) => (+data.FY >= reportPeriod - compareToYears && +data.FY <= reportPeriod))}
                    dataKeys={(chartData && chartData.length > 0)
                      ? Object.keys(chartData[0])
                        .filter((key: string) => !["FY"].includes(key))
                        .map((key: string) => ({
                          key,
                          title: FormatKeyToLabel(key),
                          isStatRow: !["PhysicalIntensity", "ProductionIntensity"].includes(key),
                          unit: key === "ProductionIntensity"
                            ? "tCO2e/AEq"
                            : key === "PhysicalIntensity"
                              ? "tCO2e/ha"
                              : ""
                        }))
                      : []
                    }
                    unitCellContent={<p className="opacity-40">tCO2e</p>}
                  />
                </>
                : <>
                  <SimpleBarChart
                    tooltipFormatter={(value: any) => {
                      const data = value.data

                      let retString = `<b>${value.seriesName}</b><br>`
                      if (data.value !== 0) retString += `Value: ${(+data.value).toLocaleString()} tCO2e<br>`
                      if (data.change) retString += `Change: ${(data.change).toLocaleString()}%`
                      return retString
                    }}
                    series={barChartData.map((data: any) => ({
                      ...data,
                      data: data.data.filter((data: any) => data.FY >= reportPeriod - compareToYears && data.FY <= reportPeriod)
                    }))}
                    categories={fyOptions
                      .filter((fy: number) => fy >= reportPeriod - compareToYears && fy <= reportPeriod)
                      .map((fy: number) => FormatFYChartTitle(fy))}
                    showZoom={false}
                  />
                </>
            }
          </>
      }

      <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
        <Box className="flex flex-col gap-4">
          <Box className="flex items-center justify-between">
            <Typography variant="h6">Stress Test</Typography>
            <IconButton onClick={() => setShowClimateChangeModal(false)}>
              <Close />
            </IconButton>
          </Box>
          <ClimateChangeToggleBar
            selectedClimateScenario={selectedClimateScenario}
            setSelectedClimateScenario={(climateScenario: ClimateScenario) => setSelectedClimateScenario(climateScenario)}
          />
          <MultiYAxisLineChart
            key={`climate-change-modal-line-chart-${selectedClimateScenario}`}
            yAxisTitles={["tCO2e", "tCO2e/AEq"]}
            animation={false}
            loading={climateChangeModalLoading}
            titles={climateChangeModalFyOptions.filter((fy: number) => fy >= reportPeriod).map(FormatFYChartTitle)}
            // minYAxisValue={GetMinYAxisValue(climateChangeModalLineChartData)}
            series={climateChangeModalLineChartData
              .map((data: any) => ({
                ...data,
                type: "line",
                symbol: selectedClimateScenario === data.climateScenario ? 'roundRect' : 'triangle',
                symbolSize: 20,
                lineStyle: {
                  width: 2,
                  type: selectedClimateScenario === data.climateScenario ? "solid" : "dashed",
                  opacity: selectedClimateScenario === data.climateScenario ? 1 : 0.4,
                  color: data.color
                },
                data: data.data
                  .filter((value: any) => +value.fy >= reportPeriod)
                  .map((value: any) => {
                    return {
                      ...value,
                      itemStyle: {
                        color: value.color,
                        opacity: selectedClimateScenario === value.climateScenario ? 1 : 0.4,
                      },
                      value: value.value,
                    }
                  })
              }))}
          />
        </Box>
      </VmModal>
    </>
  )
})

export default SummaryAEEmissionSummaryEmissionSummary
